import Evaluation from "../../assets/ImageCompo/Evaluation";
import Monitor from "../../assets/ImageCompo/Monitor";
import InputForm from "../../assets/ImageCompo/InputForm";
import { Link } from "react-router-dom";
import {FiEdit2} from "react-icons/fi";
import {useAuth} from "../../Core/Providers";
import React, {useEffect, useState} from "react";

const NodalView = (props: any) => {
  return (
      <div className="ActionCenterSection">
        <div className="row gx-0">
          <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
            <Link to="/rdss/capacity-building/about" className="d-block w-100 text-decoration-none h-100">
              <div className="tabPanelInner">
                <InputForm/>
                <h5>About RDSS Part-B</h5>
                <div className="hoverContent">
                  <div>
                    <InputForm/>
                    <h5>About RDSS Part-B</h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
            <Link to="/rdss/capacity-building/tm" className="d-block w-100 text-decoration-none h-100">
              <div className="tabPanelInner">
                <Monitor/>
                <h5>TIA Management</h5>
                <div className="hoverContent">
                  <div>
                    <Monitor/>
                    <h5>TIA Management</h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
            <Link to="/rdss/capacity-building/cs" className="d-block w-100 text-decoration-none h-100 h-100">
              <div className="tabPanelInner">
                <Evaluation/>
                <h5>Claim Status</h5>
                <div className="hoverContent">
                  <div>
                    <Evaluation/>
                    <h5>Claim Status</h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>
          <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
            <Link to="/rdss/capacity-building/modules" className="d-block w-100 text-decoration-none h-100 h-100">
              <div className="tabPanelInner">
                <Evaluation/>
                <h5>Manage Module</h5>
                <div className="hoverContent">
                  <div>
                    <Evaluation/>
                    <h5>Manage Module</h5>
                  </div>
                </div>
              </div>
            </Link>
          </div>
        </div>
      </div>
  )
};

const CapacityBuilding = (props: any) => {
  const user: any = useAuth().user();
  const [roleid, setRoleid] = useState<any>(0);
  const [discomuserid, setDiscomUserid] = useState<any>(0);
  const roleIds = [
    process.env.REACT_APP_NTC,
    process.env.REACT_APP_NTA
  ];
  useEffect(() => {
    setRoleid(user?.role_id);
    setDiscomUserid(user?.discom_user_id);
  }, []);

  return (
      <>
        <Link to="/rdss/capacity-building/knowledge-repository" className="text-decoration-none btn btn-outline-primary btn-outline-primaryDark mb-3" title="Go to Knowledge Repository">Go to Knowledge Repository</Link>
        {
          (roleIds.includes(roleid)) ?
              (<NodalView/>)
          : <div className="ActionCenterSection">
                <div className="row gx-0">
                  <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                    <Link to="/rdss/capacity-building/about" className="d-block w-100 text-decoration-none h-100">
                      <div className="tabPanelInner">
                        <InputForm/>
                        <h5>About RDSS Part-B</h5>
                        <div className="hoverContent">
                          <div>
                            <InputForm/>
                            <h5>About RDSS Part-B</h5>
                            {/*<p>To enter details of Financial, PQ SOP, Energy, REF, Sanction letter and Award details</p>*/}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                    <Link to="/rdss/capacity-building/tm" className="d-block w-100 text-decoration-none h-100">
                      <div className="tabPanelInner">
                        <Monitor/>
                        <h5>Training Management</h5>
                        <div className="hoverContent">
                          <div>
                            <Monitor/>
                            <h5>Training Management</h5>
                            {/*<p>To track physical progress of projects</p>*/}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                  <div className="col-lg-4 col-6 d-flex align-items-center justify-content-center">
                    <Link to="/rdss/capacity-building/tr" className="d-block w-100 text-decoration-none h-100 h-100">
                      <div className="tabPanelInner">
                        <Evaluation/>
                        <h5>Training Reports</h5>
                        <div className="hoverContent">
                          <div>
                            <Evaluation/>
                            <h5>Training Reports</h5>
                            {/*<p>To view evaluation of PQ, SOP and REF</p>*/}
                          </div>
                        </div>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
        }
      </>
  )
};

export default CapacityBuilding;
