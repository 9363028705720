import React, { Component } from 'react'
import { BiLinkExternal } from 'react-icons/bi'
import Footer from "../Layout/Default/Element/Footer";
import Header from "../Layout/Default/Element/Header";
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'
import "./var.scss";
export default class Help extends Component {

    render() {
        return (
            <>
                <Header />
                <InnerTemplate sublink={false} PageTitle="Help" />
                <div className='main'>
                    <div className='container'>
                        <div className='screenReader'>
                            <p>This website includes some content that is available in non-HTML format, which might not be visible properly if your browser does not have the required plug-ins. For example, Acrobat Reader software is required to view Adobe Acrobat PDF files. If you do not have this software installed on your computer, you can download it for free. The following table lists some plug-ins that you will require: </p>                            

                            <div className="table-responsive tableStyle tableBorderLeft mt-3">
                                <table className="responsiveTable table table-hover w-100">
                                    <thead>
                                        <tr>
                                            <th><strong>Document Type</strong></th>
                                            <th><strong>Download</strong></th>
                                        </tr>
                                    </thead>
                                    <tbody>
                                        <tr>
                                            <td>PDF content</td>
                                            <td><a href="http://get.adobe.com/reader/">Adobe Acrobat Reader <BiLinkExternal/></a></td>
                                        </tr>
                                        <tr>
                                            <td>Flash content</td>
                                            <td><a href="http://get.adobe.com/flashplayer/">Adobe Flash Player <BiLinkExternal/></a></td>
                                        </tr>
                                        <tr>
                                            <td>Power Point Microsoft Office Open XMLFormat Presentation (PPTX)</td>
                                            <td><a href="http://www.microsoft.com/downloads/en/details.aspx?familyid=941b3470-3ae9-4aee-8f43-c6bb74cd1466&amp;displaylang=en">Microsoft Office Compatibility Pack for Word, Excel, and PowerPoint 2007 File Formats <BiLinkExternal/></a></td>
                                        </tr>
                                        <tr>
                                            <td>MPG (video file)</td>
                                            <td><a href="http://windows.microsoft.com/en-US/windows/downloads/windows-media-player">Windows Media Player <BiLinkExternal/></a></td>
                                        </tr>
                                    </tbody>
                                </table>
                            </div>

                            <h2 className='mb-2'>Accessibility Help</h2>
                            <p>Use the accessibility options provided by this Web site to control the screen display. These options allow changing the text size and contrast scheme for clear visibility and better readability.</p>
                            
                            <h2 className='mb-2'>Changing the Text Size</h2>
                            <p>Changing the size of the text refers to making the text appearing smaller or bigger than its standard size. There are five options provided to you to set the size of the text that affect readability. These are:</p>

                            <ul className="checkList listFontNormal">
                                <li>Largest : Displays information in the largest font size.</li>
                                <li>Larger : Displays information in a font size larger than the standard font size.</li>
                                <li>Medium : Displays information in a standard font size, which is the default size.</li>
                                <li>Smaller : Displays information in a font size smaller than the standard font size.</li>
                                <li>Smallest : Displays information in the smallest font size.</li>
                                <li>The website allows you to change the text size through the Accessibility Options page.</li>
                            </ul>

                            <h2 className='mb-2'>Accessibility Options</h2>
                            <p>To change the text size, click on "A+" "A-" at the top to increase or decrease the size.</p>

                            <h2 className='mb-2'>Changing the contrast Scheme</h2>
                            <p>Changing the contrast scheme refers to applying a suitable background and text color that ensures clear readability. There are two options provided to change the contrast scheme. These are:</p>
                            <p>High contrast: Applies black color as the background and suitable colors to the foreground text on the screen to improve readability</p>
                            <p>Standard contrast: applies white color as the background and black color to the foreground text to set the default contrast.</p>                        
                            <p>Note : Changing the contrast scheme does not affect the images on the screen.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
