import React, { useEffect, useState } from 'react';
import { Link } from "react-router-dom";
import { BsSearch } from "react-icons/bs";
import { AiOutlineHome } from "react-icons/ai";
import './Header.scss';
import PowerLogo from '../../../../images/power-logo.png';
import RecLogo from '../../../../images/rec-logo.png';
import PfcLogo from '../../../../images/pfc-logo.png';
import RecLogoDark from '../../../../images/rec-logo-dark.png';
import PfcLogoDark from '../../../../images/pfc-logo-dark.png';
import { useNavigate } from "react-router-dom";
import { getCookie, setCookie } from '../../../../CommonFunctions';
import { useAuth } from '../../../../Core/Providers/AuthProvider';
import { Storage } from '../../../../Core/Services/StorageService';
// import Timer from '../../../../Core/Components/Timer';

function Header() {

    const auth = useAuth();
    let navigage = useNavigate();
    const [roleid, setRoleid] = useState<any>();
    const [username, setUsername] = useState<any>();
    useEffect(() => {
        setRoleid(Storage.get('roleid'));
        setUsername(Storage.get('username'));
    });
    // useEffect(() => {
    //     window.scrollTo(0, 0);
    //     if (getCookie('theme-mode') === 'dark-mode') {
    //         DarkMode();
    //     }
    //     if (getCookie('font-mode') === 'f-small') {
    //         SmallFont();
    //     }
    //     if (getCookie('font-mode') === 'f-plus') {
    //         PlusFont();
    //     }
    // }, [])
    const handleClickScroll = () => {
        const element = document.getElementById('schemeSection');
        if (element) {
            // 👇 Will scroll smoothly to the top of the next section
            element.scrollIntoView({ behavior: 'smooth' });
        }
    };
    const SmallFont = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        document.body.classList.remove('f-plus');
        document.body.classList.add('f-small');
        setCookie('font-mode', 'f-small');
    };
    
    const NormalFont = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        document.body.classList.remove('f-small', 'f-plus');
        setCookie('font-mode', '');
    };
    
    const PlusFont = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        document.body.classList.remove('f-small');
        document.body.classList.add('f-plus');
        setCookie('font-mode', 'f-plus');
    };
    
    const DarkMode = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        document.body.classList.remove('light-mode');
        document.body.classList.add('dark-mode');
        setCookie('theme-mode', 'dark-mode');
    };
    
    const LightMode = (e: React.MouseEvent<HTMLAnchorElement>) => {
        e.preventDefault();
        document.body.classList.remove('dark-mode');
        document.body.classList.add('light-mode');
        setCookie('theme-mode', 'light-mode');
    };

    const logout = () => {
        auth.logout()
            .then(() => {
                Storage.delete('roleid');
                Storage.delete('username');
                window.location.replace('/sign-in');
            });
    };

    return (
        <header>
            <div className='topHeader'>
                <div className='container'>
                    <div className='row align-items-center justify-content-between'>
                        <div className='col-md-12 col-lg-5'>
                            <ul className='list-unstyled'>
                                {/* <li><Link to="/feedback">Feedback</Link></li> */}
                                <li><Link to="/faq">FAQ's</Link></li>
                                {/* <li><Link to="/career">Career</Link></li> */}
                                {/* <li><Link to="/archives">Archives</Link></li> */}
                                <li><Link to="#">Sitemap</Link></li>
                                {
                                    username ? <li>Welcome! {username}</li> : null
                                }
                            </ul>
                        </div>
                        <div className='col-md-12 col-lg-7 text-end'>
                            <ul className='list-unstyled'>
                                {/* <li><Timer /></li> */}
                                <li><Link to="#" onClick={handleClickScroll}>Skip to Main Content</Link></li>
                                <li><Link to="/screen-reader-access">Screen Reader Access</Link></li>
                                <li><Link to="" onClick={SmallFont}>A-</Link> <Link to="" onClick={NormalFont}>A</Link> <Link to="" onClick={PlusFont}>A+</Link></li>
                                <li><Link to="" onClick={LightMode} className='lightMode'>Light</Link> <Link to="" onClick={DarkMode} className='darkMode'>Dark</Link></li>
                                {/* <li><Link to="/">English</Link></li> */}
                                {/* <li><Link to="#" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><BsSearch /><span className='searchBtn'>Search</span></Link></li> */}
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
            <div className='container'>
                <div className='mainHeader'>
                    <div className='logo'>
                        <Link to="/"><img src={PowerLogo} width='170' alt='Logo' className="img-fluid mainLogo" /></Link>
                        <a href="https://recindia.nic.in/" className='normal-theme' target="_blank"><img src={RecLogo} width='140' alt='REC' className="img-fluid me-2 recLogo" /></a>
                        <a href="https://www.pfcindia.com/" className='normal-theme' target="_blank" ><img src={PfcLogo} width='80' alt='PFC' className="img-fluid pfcLogo" /></a>
                        <a href="https://recindia.nic.in/" className='dark-theme' target="_blank"><img src={RecLogoDark} width='140' alt='REC' className="img-fluid me-2 recLogo" /></a>
                        <a href="https://www.pfcindia.com/" className='dark-theme' target="_blank" ><img src={PfcLogoDark} width='80' alt='PFC' className="img-fluid pfcLogo" /></a>
                    </div>
                    <div className='navMenu d-flex align-items-center'>
                        <div className='mainNav'>
                            <nav className="navbar navbar-expand-xl">
                                <div className="container-fluid">
                                    <button className="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                                        <span className="navbar-toggler-icon"></span>
                                    </button>
                                    <div className="offcanvas offcanvas-start" id="navbarSupportedContent">
                                        <button type="button" className="btn-close d-xl-none text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                                        <ul className="navbar-nav me-auto mb-2 mb-lg-0">
                                            <li className="nav-item">
                                                <Link className="nav-link active" title='Home' aria-current="page" to="/"><AiOutlineHome /></Link>
                                            </li>
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/about-us">About Us</Link>
                                            </li>
                                            {/* <li className="nav-item">
                                        <Link className="nav-link" to="/quick-links">Quick Links</Link>
                                        </li>      */}
                                            {/* <li className="nav-item">
                                        <Link className="nav-link" to="/grievances">Grievances</Link>
                                        </li>      */}
                                            <li className="nav-item">
                                                <Link className="nav-link" to="/contact-us">Contact us</Link>
                                            </li>
                                            {
                                                username ?
                                                    <>
                                                        <li className="nav-item">
                                                            <Link className="nav-link" to="/change-password">Change Password</Link>&nbsp;
                                                        </li>
                                                        <li className="nav-item">
                                                            <div className='loginBtn'>
                                                                <Link className="nav-link" onClick={() => logout()} to="#">Logout</Link>
                                                            </div>
                                                        </li>
                                                    </>
                                                    :
                                                    <li className="nav-item">
                                                        <div className='loginBtn'>
                                                            <Link className="nav-link" to="/sign-in">Sign in</Link>
                                                        </div>
                                                    </li>
                                            }
                                        </ul>
                                    </div>
                                </div>
                            </nav>
                        </div>
                        {/* <div className='searchBar d-none'>
                        <Link to="javascript:void();" data-bs-toggle="offcanvas" data-bs-target="#offcanvasTop" aria-controls="offcanvasTop"><BsSearch /></Link>
                        </div> */}
                        {
                            username ?
                                <>
                                    {/* <Link className="nav-link" to="/change-password">Change Password</Link>&nbsp; */}
                                    {/* <div className='loginBtn'>
                                        <Link className="nav-link" onClick={() => logout()} to="#">Logout</Link>
                                    </div> */}

                                    {/* <div className='loginBtn'>
                                        <div className='userProfile'>
                                            <div className='userIcon'><img src={ManImg} width="40" height="40" className="rounded-circle"/></div>
                                            <div className="userName">
                                                <Dropdown>
                                                    <Dropdown.Toggle id="dropdown-basic">
                                                        Abhishek Shah
                                                    </Dropdown.Toggle>

                                                    <Dropdown.Menu>
                                                        <Dropdown.Item href="#">Edit Profile</Dropdown.Item>
                                                        <Dropdown.Item href="#">Bank Details</Dropdown.Item>
                                                        <Dropdown.Item href="#">Discom Details</Dropdown.Item>
                                                        <Dropdown.Item href="#" onClick={() => logout()}>Logout</Dropdown.Item>
                                                    </Dropdown.Menu>
                                                </Dropdown>
                                                <span>DISCOM-1</span>
                                            </div>
                                        </div>
                                    </div> */}
                                </>
                                :
                                <>
                                    {/* <div className='loginBtn'>
                                    <Link className="nav-link" to="/sign-in">Sign in</Link>
                                </div> */}
                                </>
                        }
                    </div>
                </div>
            </div>
            <div className="offcanvas offcanvas-top searchSlide" id="offcanvasTop" aria-labelledby="offcanvasTopLabel">
                <div className="offcanvas-body">
                    <div id="cover" className="offCanvasSearch d-flex align-items-center">
                        <form action="">
                            <div className="search-box">
                                <input className="search-input" type="text" placeholder="Search something.." />
                                <button className="search-btn"><BsSearch /></button>
                            </div>
                        </form>
                        <button type="button" className="btn-close" data-bs-dismiss="offcanvas" aria-label="Close"></button>
                    </div>
                </div>
            </div>
        </header>
    );
}

export default Header;
function logout(arg0: () => void) {
    throw new Error('Function not implemented.');
}

