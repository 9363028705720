import React, { useEffect, useRef, useState } from 'react'
import { Link } from 'react-router-dom'

import ProgressDetails from '../../../assets/ImageCompo/ProgressDetails'

import Footer from "../../../Layout/Default/Element/Footer";
import Header from "../../../Layout/Default/Element/Header";
import InnerTemplate from '../../../Components/InnerTemplate/InnerTemplate'
import "../../../css/style.scss";
import { Http } from '../../../Core/Services/HttpService';
import { Storage } from '../../../Core/Services/StorageService'

const AllProgressDetails = (props: any) => {
    const [isLoaded, setIsLoaded] = useState(false);
    const [discomid, setDiscomid] = useState<any>(0);
    const [isLoading, setIsLoading] = useState(false);
    const [error, setError] = useState(null);
    const [submittedStatus, setSubmittedStatus] = useState<any>(0);
    const getSubmittedStatus = () => {
        (async () => {
            if (discomid) {
                setIsLoading(true);
                await Http.get("/apiv1/sanction-block-status?form_id=&discom_id=" + discomid)
                    .then((res) => {
                        setIsLoaded(true);
                        setIsLoading(false);
                        setSubmittedStatus(res.submitted_status);
                    })
                    .catch((error) => {
                        setIsLoaded(true);
                        setError(error);
                    });
            }
        })();
    };

    useEffect(() => {
        setDiscomid(Storage.get('discom_id'));
    }, [discomid]);

    useEffect(() => {
        (async () => {
            getSubmittedStatus();
        })();
    }, [discomid]);

    return (
        <>
            <Header />
            <section className="InnerBanner">
                <div className="container">
                    <ul className="BreadCumb list-unstyled mb-0">
                        <li><Link to="/" className="text-decoration-none">Home</Link></li>
                        <li><Link to="/rdss" className="text-decoration-none">RDSS</Link></li>
                        <li><Link to="/monitoring" className="text-decoration-none">Monitoring</Link></li>
                        <li>AllProgressDetails</li>
                    </ul>
                </div>
            </section>
            {/* <InnerTemplate PageTitle="AllProgressDetails" /> */}
            <div className="mainWrap inputFromNew">
                <div className="container">
                    <div className='aboutAccordioin d-block mt-5'>
                        <div className='Accordion-group mw-100'>
                            <div className='Tab-panel'>
                                <div className='ActionCenterSection bgSizeCover'>
                                    <div className="row gx-0">
                                        <div className="col-lg-6 col-6 d-flex align-items-center justify-content-center">
                                            <Link to={submittedStatus == 1 ? "/progress-details" : "#"} className="d-block w-100 text-decoration-none h-100">
                                                <div className="tabPanelInner">
                                                    <ProgressDetails />
                                                    <h5>District-wise Progress Details</h5>
                                                    <p className='text-white px-4 display-6'>Enter progress Details for Smart Metering, Loss Reduction and Modernization Works for the Financial Year </p>
                                                </div>
                                            </Link>
                                        </div>
                                        <div className="col-lg-6 col-6 d-flex align-items-center justify-content-center">
                                            <Link to={submittedStatus == 1 ? "/feeder-progress-details/"+discomid : "#"} className="d-block w-100 text-decoration-none h-100">
                                                <div className="tabPanelInner">
                                                    <ProgressDetails />
                                                    <h5>Feeder-wise Progress Details</h5>
                                                    <p className='text-white px-4 display-6'>Enter cumulative progress details with breakup of feeders for Loss Reduction </p>
                                                </div>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                                {
                                    submittedStatus == 0 ?
                                        <>
                                            <div className="mt-2 alert alert-info">Please submit sanction data</div>
                                        </>
                                        : null
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <Footer />
        </>
    )
}
export default AllProgressDetails;