import React, { useState, useCallback, useEffect, useRef } from "react";
import InnerTemplate from "../Components/InnerTemplate/InnerTemplate";
import Header from "../Layout/Default/Element/Header";
import Footer from "../Layout/Default/Element/Footer";
import { Link, Navigate } from "react-router-dom";
import FormSteps from "../Components/InputForms/Section1/FormStep"
import Section2 from "../Components/InputForms/Section2/Section2"
import qs from 'qs';
import { BiUpload } from "react-icons/bi";
import PdfIcon from '../images/pdf.svg';


// Images
import Arrow from "../images/right-arrow.svg";
import { BsFileEarmarkPdf } from "react-icons/bs";
import { Http } from "../Core/Services/HttpService";
import { useLayout } from "../Core/Providers/LayoutProvider";
import { Storage } from "../Core/Services/StorageService";

const InputForm = () => {
    const layout = useLayout();
    const syear: any = useRef(null);
    const [pdfLink, setPdfLink] = useState('#');
    const [pdfParams, setPdfParams] = useState({});
    const [year, setYear] = useState("2022");
    const [selected, setSelected] = useState("Section1");
    const formData: any = useRef(null);
    let layoutDataSubscribe: any;

    const updatePdfParams = (params: any) => {
        setPdfParams((prevParams) => {
            const newParams = {...prevParams, ...params};
            return newParams;
        });
    };


    useEffect(() => {
        (async () => {
            updatePdfParams({
                roleid: Storage.get('roleid'),
                discom_user_id: Storage.get('discom_user_id'),
                discomid: Storage.get('discom_id')
            });
            layoutDataSubscribe = await layout.data.subscribe((res: any) => {
                if (res.pdfParams !== undefined) {
                    updatePdfParams(res.pdfParams);
                }
            });
        })();
        return () => {
            if (layoutDataSubscribe !== undefined) {
                layoutDataSubscribe.unsubscribe();
            }
        }
    }, []);

    useEffect(() => {
        setPdfLink(process.env.REACT_APP_API_ENDPOINT+'/apiv1/pdfdownload/?' + qs.stringify(pdfParams));
    }, [pdfParams]);

    useEffect(() => {
        updatePdfParams({ year: year });
    }, [year]);

    const refreshform = () => {
        setYear(syear.current.value);
        setSelected("Section1");
    }

    const pdfDownload = () => {

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        let myFormData = new FormData();
        Http.post('/apiv1/pdfdownload', myFormData, config)
            .then((res) => {
                console.log(res);
            });
    }

    useEffect(() => {
        setSelected("Section1");
    }, []);

    const changeHandler = (e: any) => {
        setSelected(e.target.value);
    };
    const sanctionLetterMainForm = () => {
        document.body.classList.add('sanctionLetterMainForm');
    }
    const FinancialMainForm = () => {
        document.body.classList.remove('sanctionLetterMainForm');
    }
    return (
        <>
            <Header />
            <div className="mainWrap">
                <InnerTemplate PageTitle="Input Form" />
                <section className="financialForm">
                    <div className="container">
                        <div className="financialMainFrom inputFromNew">
                            <div className="tab-content border-0 p-0">
                                <div className="tab-pane fade show active" id="InputForm" role="tabpanel" aria-labelledby="InputFormTab">
                                    <div className='radioTitleSection'>
                                        <div className='row mx-0 gx-0 justify-content-between'>
                                            <div className='col-lg-12 col-xl-7 col-xxl-5'>
                                                <div className="radioYear">
                                                    <div className="row justify-content-center align-items-center">
                                                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="form-check">
                                                                <input type="radio" className="form-check-input" name="section" value="Section1" id="Section1" checked={selected === "Section1"} onChange={changeHandler} onClick={FinancialMainForm} />
                                                                <label className="form-check-label" htmlFor="Section1">
                                                                    Evaluation Details
                                                                </label>
                                                            </div>
                                                        </div>
                                                        <div className="col-sm-6 col-md-6 col-lg-4 col-xl-6">
                                                            <div className="form-check last">
                                                                <input type="radio" className="form-check-input" name="section" value="Section2" id="Section2" checked={selected === "Section2"} onChange={changeHandler} onClick={sanctionLetterMainForm}/>
                                                                <label className="form-check-label" htmlFor="Section2">Sanction and Award Details</label>
                                                            </div>
                                                        </div>                                                        
                                                    </div>
                                                </div>
                                            </div>
                                            {
                                                selected == 'Section1' &&
                                                <div className="col-lg-12 col-xl-5 col-xxl-4 text-end">
                                                    <div className="fYearMonth">
                                                        <div className="row gx-0 justify-content-end mt-3 mt-xl-0">
                                                            <div className="col-auto col-md-auto col-lg-auto xl-4 sanctionLetterYearNone">
                                                                <div className="year resYear">
                                                                    <label>Select Year (FY)</label>
                                                                    <select defaultValue={year} ref={syear} className="form-select selectYear" aria-label="Default select example">
                                                                        <option value="2022">2022</option>
                                                                        <option value="2023">2023</option>
                                                                        <option value="2024">2024</option>
                                                                        <option value="2025">2025</option>
                                                                        <option value="2026">2026</option>
                                                                    </select>
                                                                    <Link to="#" onClick={() => refreshform()}>
                                                                        <div className="inputBtn">
                                                                            <span><img src={Arrow} /></span>
                                                                        </div>
                                                                    </Link>
                                                                </div>
                                                            </div>
                                                            <div className="col-auto">
                                                                {/*selected == 'Section1' ?*/
                                                                    <>
                                                                        <Link to={pdfLink} className="pdfIcn" target="_blank"><img src={PdfIcon}/></Link>
                                                                    </>
                                                                    /*: null*/
                                                                }
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                    <div aria-hidden={selected !== "Section1" ? true : false}><FormSteps year={year} /></div>
                                    <div aria-hidden={selected !== "Section2" ? true : false}><Section2 year={year} /></div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>

                {/* <Container>
                <Row>
                    <Col>
                        <MasterForm />
                    </Col>
                </Row>
            </Container> */}
            </div>
            <Footer />
        </>
    );
};

export default InputForm;