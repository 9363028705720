import React, { useEffect, useState } from 'react'
import Header from "../Layout/Default/Element/Header";
import Footer from "../Layout/Default/Element/Footer";
import InnerTemplate from "../Components/InnerTemplate/InnerTemplate";
import useCurrentWidth from "../Components/Accordion/useDebounce";
import Accordion from "../Components/Accordion/Accordion";

// Images
import Guidelines from "../Components/RdssTabs/Guidelines";
import SchemeOverview from "../Components/RdssTabs/SchemeOverview";
import ActionCenter from "../Components/RdssTabs/ActionCenter";
import ImportantDocuments from "../Components/RdssTabs/ImportantDocuments";
import QualityMonitoring from "../Components/RdssTabs/QualityMonitoring";
import DocumentCentre from "../Components/RdssTabs/DocumentCentre";
import AuditHistory from "../Components/RdssTabs/AuditHistory";
import Notifications from '../Components/Notifications/Notifications';
import { Navigate, useLocation } from "react-router-dom";
import FundReleased from "../Components/FundReleased/IndexPage";
import MisReports from '../Reports/MisReports/index';
import { Storage } from '../Core/Services/StorageService';
import CapacityBuilding from "../Components/RdssTabs/CapacityBuilding";
import "./var.scss";
const AboutPage = () => {
  const IsAuth = (props: any) => {
    let roleid = Storage.get('roleid');

    //  console.log(roleid);
    return <>
      {(roleid) ? props.children : <Navigate to={'/sign-in'} />}
    </>;
  }

  const location = useLocation();
  const [isOpenGlTab, setOpenGlTab] = useState(false);
  const [isOpenQmTab, setOpenQmTab] = useState(false);
  const [isOpenCbTab, setOpenCbTab] = useState(false);
  const [isOpenActionTab, setOpenActionTab] = useState(false);
  const [isOpenNotificationsTab, setOpenNotificationsTab] = useState(false);
  const [isOpenFdTab, setOpenFdTab] = useState(false);
  const [isOpenMisReportsTab, setOpenMisReportsTabb] = useState(false);

  const onOffAll = () => {
    setOpenGlTab(false);
    setOpenQmTab(false);
    setOpenActionTab(false);
    setOpenNotificationsTab(false);
    setOpenFdTab(false);
    setOpenMisReportsTabb(false);
    setOpenCbTab(false);
  };

  useEffect(() => {
    onOffAll();
    if (location.hash == '#Guidelines') {
      setOpenGlTab(true);
    } else if (location.hash == '#QualityMonitoring') {
      setOpenQmTab(true);
    } else if (location.hash == '#FundReleased') {
      setOpenFdTab(true);
    } else if (location.hash == '#Notifications') {
      setOpenNotificationsTab(true);
    } else if (location.hash == '#MISReports') {
      setOpenMisReportsTabb(true);
    }else if(location.hash == '#CapacityBuilding'){
      setOpenCbTab(true);
    }else{
      setOpenNotificationsTab(false);
      setOpenQmTab(false);
      setOpenActionTab(true);
    }
  }, [onOffAll, location.hash]);

  const accordionPanels = [
    {
      id: "Dashboard",
      heading: "Dashboard",
      content: (
        <p>content in the second Accordion panel</p>
      ),
      link: "/dashboard"
    },
    {
      id: "Guidelines",
      open: isOpenGlTab,
      heading: "Guidelines, SBD & Sanction Letter",
      content: (
        <Guidelines />
      )
    },
    {
      id: "ImportantDocuments",
      heading: "Important documents",
      content: (
        <IsAuth><ImportantDocuments /></IsAuth>
      )
    },
    {
      id: "SchemeOverview",
      heading: "Scheme Overview",
      content: (
        <SchemeOverview />
      )
    },
    {
      id: "ActionCentre",
      open: isOpenActionTab,
      heading: "Action Centre",
      content: (
        <>
          <ActionCenter />
        </>
      )
    },
    {
      id: "CapacityBuilding",
      open: isOpenCbTab,
      heading: "Capacity Building",
      content: (
          <CapacityBuilding/>
      ),
    },
    {
      id: "FundReleased",
      heading: "Fund Released",
      open: isOpenFdTab,
      content: (
        <>
          <FundReleased />
        </>
      )
    },
    {
      id: "DocumentCentre",
      heading: "Document Centre",
      content: (
        <IsAuth><DocumentCentre /></IsAuth>
      )
    },
    {
      id: "Notifications",
      open: isOpenNotificationsTab,
      heading: "Notifications",
      content: (
        <Notifications module={'RDSS'} />
      )
    },
    {
      id: "DMSChecklist",
      heading: "DMS Checklist",
      content: (
        <p>content in the second Accordion panel</p>
      ),
      link: "/dms-checklist"
    },
    {
      id: "MISReports",
      open: isOpenMisReportsTab,
      heading: "MIS Reports",
      content: (
        <MisReports />
      )
    },
  ];

  const mobileBreakpoint = 769;
  const isMobile = useCurrentWidth() < mobileBreakpoint;

  return (
    <>
      <Header />
      <InnerTemplate PageTitle="Revamped Distribution Sector Scheme (RDSS)" />
      <div className="mainWrap">
        <div className="container">
          <div className="aboutAccordioin">
            <Accordion asTabs={!isMobile} onlyOneOpenAccordion={true} panels={accordionPanels} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default AboutPage;