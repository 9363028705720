import React from "react";
import Header from "../Layout/Default/Element/Header";
import Footer from "../Layout/Default/Element/Footer";
import InnerTemplate from "../Components/InnerTemplate/InnerTemplate";
import useCurrentWidth from "../Components/Accordion/useDebounce";
import Accordion from "../Components/Accordion/Accordion";
import "./var.scss";
// Images
import Guidelines from "../Components/RdssTabs/Guidelines";
import SchemeOverview from "../Components/RdssTabs/SchemeOverview";
import ActionCenter from "../Components/RdssTabs/ActionCenter";
import ImportantDocuments from "../Components/RdssTabs/ImportantDocuments";
import TPQMA from "../Components/RdssTabs/TPQMA";
import DocumentCentre from "../Components/RdssTabs/DocumentCentre";
import AuditHistory from "../Components/RdssTabs/AuditHistory";
import Notifications from '../Components/Notifications/Notifications';
import EaActionCenter from "../Components/EngeryAccounting/EaActionCenter";

const EA = () => {
  const accordionPanels = [
    {
      id: "Dashboard",
      heading: "Dashboard",
      content: (
        <p></p>
      )
    },
    {
      id: "Guidelines",
      heading: "Guidelines & SBD",
      content: (
        <Guidelines/>
      )
    },
    {
      id: "ImportantDocuments",
      heading: "Important documents",
      content: (
        <ImportantDocuments/>
      )
    },
    {
      id: "SchemeOverview",      
      heading: "Scheme Overview",
      content: (
        <SchemeOverview/>
      )
    },
    {
      id: "ActionCentre",      
      open: true,
      heading: "Action Centre",
      content: (
        <>
        <EaActionCenter/>
        </>
      )
    },
    {
      id: "TPQMA",
      heading: "TPQMA",
      content: (
        <TPQMA/>
      )
    },
    {
      id: "DocumentCentre",
      heading: "Document Centre",
      content: (
        <DocumentCentre/>
      )
    },
    {
      id: "Notifications",
      heading: "Notifications",
      content: (
        <Notifications/>
      )
    },
  ];

  const mobileBreakpoint = 769;
  const isMobile = useCurrentWidth() < mobileBreakpoint;

  return (
    <>
      <Header />
      <InnerTemplate page="EA" PageTitle="Energy Accounting" />
      <div className="mainWrap">
        <div className="container">
          <div className="aboutAccordioin">
            <Accordion asTabs={!isMobile} onlyOneOpenAccordion={true} panels={accordionPanels} />
          </div>
        </div>
      </div>
      <Footer />
    </>
  );
}

export default EA;