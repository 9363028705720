import React, { Component } from 'react'
import Footer from "../Layout/Default/Element/Footer";
import Header from "../Layout/Default/Element/Header";
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'
import "./var.scss";
export default class TermsConditions extends Component {

    render() {
        return (
            <>
                <Header />
                <InnerTemplate sublink={false} PageTitle="Terms & Conditions" />
                <div className='main'>
                    <div className='container'>
                        <div className='screenReader'>
                            <p>The official Integrated Digital Platform designed by the MoP, REC and PFC has been developed to monitor all power sector schemes and integrate data collected across finance, operations & scheme performance for all Discoms. The documents and information displayed on the website are for reference purposes only. The information contained on the website is based on the inputs received from DISCOMs, REC, PFC, and MoP. Though all efforts have been made to ensure the accuracy of the content, the same should not be construed as a statement of law or used for any legal purposes. ​</p>
                            <p>In some instances, this website contains links to other website/portals. These links have been placed for the user's convenience. REC is not responsible for the contents and reliability of the links. Mere presence of the link or its listing on this website should not be assumed as endorsement of any kind. REC cannot guarantee their functioning and has no control over the availability of linked pages at all times.</p>
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
