import React, { Component, useEffect, useState } from 'react'
import { Accordion, Alert } from 'react-bootstrap'
import Footer from "../Layout/Default/Element/Footer";
import Header from "../Layout/Default/Element/Header";
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'
import { Http } from '../Core/Services/HttpService'
import { useStateMounted } from '../Core/Hooks'
import "./var.scss";
const RenderFaq = (props?: any) => {
    const [faqs, setFaqs] = useState<any[]>([]);
    const [isLoaded, setIsLoaded] = useState(false);
    const [error, setError] = useStateMounted<string>();

    const getFaqs = () => {
        (async () => {
            setFaqs([]);
            let footerspinner: any = document.getElementById('footer-spinner');
            if (footerspinner)
                footerspinner.classList.remove('d-none');
            Http.get('/apiv1/getfaqs')
                .then((res) => {
                    if (res._resultflag && res.faqs) {
                        if (Array.isArray(res.faqs)) {
                            if (!res.faqs.length) setError("No data found.");
                            setIsLoaded(true);
                            setFaqs(res.faqs);
                            console.log(faqs);
                        } else {
                            setError('System error occurred!! please try again.');
                            setIsLoaded(true);
                        }
                        setIsLoaded(true);
                    } else if (!res._resultflag) {
                        setError(res.message);
                    } else {
                        setError('No data found.');
                    }
                    setIsLoaded(true);
                }).catch((error) => {
                    setError('System error occurred!! please try again.');
                    setIsLoaded(true);
                })
                .finally(() => {
                    setIsLoaded(true);
                    if (footerspinner)
                        footerspinner.classList.add('d-none');
                });
        })();
    };

    useEffect(() => {
        (async () => {
            getFaqs();
        })();
    }, [props.apiurl]);

    if (error) {
        return (
            <div><Alert className='mt-3 mb-3' variant="danger">{error}</Alert></div>
        );
    } else if (!isLoaded) {
        return ( <div>Loading...</div> );
    } else {
        return (
            <>
                <div className='screenReader'>
                    <Accordion defaultActiveKey="0">
                        {faqs && faqs.map((faq, index) => {
                            return (
                                <Accordion.Item eventKey={index.toString()}>
                                    <Accordion.Header>{faq.faq_question}</Accordion.Header>
                                    <Accordion.Body>
                                        <div dangerouslySetInnerHTML={{ __html: faq.faq_answer }}></div>
                                    </Accordion.Body>
                                </Accordion.Item>
                            )
                        })}
                    </Accordion>
                </div>
            </>
        );
    }
}

const Faq = (props?: any) => {
    return (
        <>
            <Header />
            <InnerTemplate sublink={false} PageTitle="FAQs" />
            <div className='main'>
                <div className='container'>
                    <>
                    <RenderFaq apiurl={"/apiv1/getfaqs"} />
                    </>
                </div>
            </div>
            <Footer />
        </>
    );
}
export default Faq;