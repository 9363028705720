import React, { Component } from 'react'
import Footer from "../Layout/Default/Element/Footer";
import Header from "../Layout/Default/Element/Header";
import InnerTemplate from '../Components/InnerTemplate/InnerTemplate'
import "./var.scss";
export default class Disclaimer extends Component {

    render() {
        return (
            <>
                <Header />
                <InnerTemplate sublink={false} PageTitle="Disclaimer" />
                <div className='main'>
                    <div className='container'>
                        <div className='screenReader'>
                            <p>This website is intended to monitor all power sector schemes and integrate data for DISCOMs, REC, PFC, and MoP, and does not purport to be a legal document. In case of any variance between what has been stated herein and that contained in the relevant Acts, rules, regulations, policy statements etc. of REC, the latter shall prevail.</p>

                            <p>REC does not assume any legal liability or responsibility for the accuracy, completeness or usefulness of any information, or process disclosed and does not represent that the use of any information contained herein would not infringe privately owned rights. Reference herein to any specific commercial product, process, trademark, manufacturer or otherwise does not constitute or imply its endorsement, recommendation or favouring by REC thereof. The view and opinions of the originators expressed therein do not necessarily state or reflect those of REC.</p>

                            <p>The information obtained at/or through this site is not and should not be construed as an offer to sanction any loan or any other assistance. The terms and conditions on which the loans are sanctioned and disbursed by REC are subject to changes from time to time.</p>

                            <p>While the site may be updated with changes periodically, REC does not guarantee that this site reflects the latest amendments/ information at all times or at any time.</p>

                            <p>Under no circumstances, including but not limited to negligence, shall REC or its directors or employees be liable for any special or consequential damages that are alleged to have resulted from the use and/or inability to use this site or information contained within, even if REC and/or its employees or directors have been notified of the possibilities of potential damages or losses.</p>

                            <p>The information provided herein, and any communications subsequently entertained or responded to are/shall be free and are/shall be outside the purview of any consumer protection law. Any recipient or user of any information or material in this site may use/rely upon the information or material entirely at this/its own risk.</p>

                            <p>The content of this site shall not be translated, distributed, or published, either partly or fully, by the recipient or any other person for any purpose, without the prior written consent of REC.</p>                        
                        </div>
                    </div>
                </div>
                <Footer />
            </>
        )
    }
}
