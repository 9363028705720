import React, { useEffect, useState } from "react";
import Header from "../Layout/Default/Element/Header";
import Footer from "../Layout/Default/Element/Footer";
import { Http } from "../Core/Services/HttpService";
import { init, validatefrm } from "../assets/js/formbuilder";
import { useNavigate, Link, useLocation, Navigate } from "react-router-dom";
import { loadCaptchaEnginge, LoadCanvasTemplate, validateCaptcha } from "react-simple-captcha";
import { BsEyeSlash, BsEye } from "react-icons/bs";
import { Jsencrypt } from "../Core/Services/JsencryptService";
import { useAuth } from "../Core/Providers";
import { set } from "lodash";
import "./var.scss";
const SignIn = () => {

  const user = useAuth().user();
  const [passwordType, setPasswordType] = useState("password");
  const [passwordInput, setPasswordInput] = useState("");
  const [userInput, setUserInput] = useState("");
  const navigate = useNavigate();
  const location = useLocation();
  const from = (location.state as { from: any })?.from?.pathname || "";
  const [err, setErr] = useState('');
  const [isSubmitted, setIsSubmitted] = useState(false);

  useEffect(() => {
    if (user.id === undefined) {
      loadCaptchaEnginge(4);
    }
  }, []);

  const handlePasswordChange = (evnt: any) => {
    setPasswordInput(evnt.target.value);
  }

  const handleUserChange = (evnt: any) => {
    evnt.preventDefault();
    setUserInput(evnt.target.value);
  }

  const showPassword = () => {
    if (passwordType === "text")
      setPasswordType("password");
    else
      setPasswordType("text");
  };

  function onSubmit(e: any) {
    e.preventDefault();
    setErr("");
    let submitform = validatefrm();
    if (submitform) {
      setIsSubmitted(true);
      let myFormData = new FormData(e.target);
      const captcha = myFormData.get('captcha');
      if (captcha && !validateCaptcha(captcha)) {
        setIsSubmitted(false);
        setErr("Invalid Captcha");
      } else {
        // Email Encryption
        const encEmail: any = Jsencrypt.encrypt(myFormData.get('email'));
        myFormData.set('email', encEmail);
        // Password Encryption
        const encPwd: any = Jsencrypt.encrypt(myFormData.get('pwd'));
        myFormData.set('pwd', encPwd);

        const config = { headers: { 'Content-Type': 'multipart/form-data' } };
        Http.post('/apiv1/login', myFormData, config)
          .then((res: any) => {
            if (res._resultflag == '1') {
              res.data = Jsencrypt.decrypt(res.data);
              let tokendata = res.data;
              navigate('/verification-otp', { replace: true, state: { email: tokendata.email, returnUrl: from } });
            }
            else {
              setErr(res.message ?? 'Something went wrong, Please try again.');
            }
          })
          .catch((err: any) => {
            setErr('Something went wrong, Please try again.');
          }).finally(() => {
            setIsSubmitted(false);
          });
      }
    }
  }

  setTimeout(() => {
    init();
  }, 1000);

  return (
    (user.id !== undefined)
      ? <Navigate to="/" />
      : <>
        <Header />
        {isSubmitted && (<div className="spinner-container">
          <div className="spinner-outer">
            <div className="loading-spinner"></div>
          </div>
        </div>
        )}
        <div className="mainWrap">
          <div className="loginWrap">
            <div className="login-main">
              <div className="container">
                <div className="row justify-content-center">
                  <div className="col-md-6 col-sm-8 col-lg-6 col-xl-5 col-xxl-4">
                    <div className="position-relative loginPosition">
                      <div className="loginbox">
                        <h4 className="text-white">Sign In</h4>
                        <form noValidate onSubmit={onSubmit}>
                          {err != '' ?
                            <div className="alert alert-danger">{err}</div>
                            : null
                          }
                          <div className="form-group">
                            <div className="form-field">
                              <label className="control-label" htmlFor="email">Username (Email)</label>
                              <input data-required="Y" autoComplete="off" className="form-control" type="email" name="email" id="email" onChange={handleUserChange} value={userInput} />
                            </div>
                          </div>
                          <div className="form-group">
                            <div className="form-field">
                              <label className="control-label" htmlFor="pwd">Password</label>
                              <input data-required="Y" className="form-control" autoComplete="off" type={passwordType} name="pwd" id="pwd" onChange={handlePasswordChange} value={passwordInput} />
                              <button type="button" onClick={showPassword} className="form-control pass-show">
                                {passwordType === "password" ? <BsEyeSlash /> : <BsEye />}
                              </button>
                            </div>
                          </div>
                          <div className="form-group">
                            <LoadCanvasTemplate reloadColor="white" />
                          </div>
                          <div className="form-group">
                            <div className="form-field">
                              <input data-required="Y" type="text" className="form-control" id="captcha" name="captcha" placeholder="Enter the Captcha" autoComplete="off" />
                            </div>
                          </div>
                          <div className="form-group">
                            <div >
                              <Link className="nav-link text-white" to="/forgot-password">Forgot Password</Link>
                            </div>
                            <div className="formBtnSection">
                              <button {...(isSubmitted) ? { disabled: true } : {}} className="btnSubmit" type="submit">Login</button>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <Footer remove="d-none" />
      </>
  );
}

export default SignIn;
