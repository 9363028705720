import { Http } from "../../Core/Services/HttpService";

export const $ = (obj) => {
  return document.getElementById(obj);
}

export const toFixed = (val, decimal = 0) => {
  val = (val + "").replace(/[^\-.0-9]+/g, '');
  if (val === '') val = decimal === 0 ? 0 : "0.00";
  // return val;
  return (val) ? new Intl.NumberFormat("en-IN", {
    minimumFractionDigits: decimal,
    maximumFractionDigits: decimal,
  }).format(val) : val;
}

export const strToNumber = (val) => {
  //return val;
  return (val) ? (val + "").replaceAll(",", "") : val;
}

export class functions {

  addMethod(cal, func) {
    this.removeMethod(cal);
    this[cal] = func;
  }

  removeMethod(cal) {
    if (this[cal]) {
      delete this[cal];
    }
  }

  runFormula() {
    RunFormulas();
  };

  // Date Status Update
  updatestatus() {
    let id1 = arguments[0].trim() + '_' + arguments[1].trim();
    let id2 = arguments[0].trim() + '_' + arguments[2].trim();
    let id3 = arguments[0].trim() + '_' + arguments[3].trim();

    if ($(id2).value && $(id1).value) {
      if ($(id2).value > $(id1).value) {
        $(id3).value = 'Non-compliant';
        $(id3).classList.add('red');
        $(id3).classList.remove('green');
      }
      else {
        $(id3).value = 'Compliant';
        $(id3).classList.add('green');
        $(id3).classList.remove('red');
      }
    } else {
      $(id3).value = '';
    }

  }

  // Radio Button (Yes/No) Status Update
  updatestatusYesNo() {
    let id1 = arguments[0].trim() + '_' + arguments[1].trim() + '_yes';
    let id2 = arguments[0].trim() + '_' + arguments[1].trim() + '_no';
    let id3 = arguments[0].trim() + '_' + arguments[2].trim();

    if ($(id1).checked) {
      $(id3).value = 'Compliant';
      $(id3).classList.add('green');
      $(id3).classList.remove('red');
    }
    if ($(id2).checked) {
      $(id3).value = 'Non-compliant';
      $(id3).classList.add('red');
      $(id3).classList.remove('green');
    }

    // Hide Show SoP-3.1.1
    if (id1 === 'pqsop_details_sop_3_1_1_tariff_ubsidy_details_text_yes' && id2 === 'pqsop_details_sop_3_1_1_tariff_ubsidy_details_text_no') {
      if ($(id1).checked)
        $('if_yes').style.display = 'contents';
      else
        $('if_yes').style.display = 'none';
    }
  }

  updatestatusYesNoPq7() {
    let id1 = arguments[0].trim() + '_' + arguments[1].trim() + '_yes';
    let id2 = arguments[0].trim() + '_' + arguments[1].trim() + '_no';
    let id3 = arguments[0].trim() + '_' + arguments[2].trim();

    if ($(id1).checked) {
      $(id3).value = 'Compliant';
      $(id3).classList.add('green');
      $(id3).classList.remove('red');
    }
    if ($(id2).checked) {
      let id4 = 'pqsop_details_pq7_target_for_payable_days';
      let id5 = 'pqsop_details_pq7_payable_days';

      if (strToNumber($(id5).value) * 1 <= strToNumber($(id4).value) * 1) {
        $(id3).value = 'Compliant';
        $(id3).classList.add('green');
        $(id3).classList.remove('red');
      } else {
        $(id3).value = 'Non-compliant';
        $(id3).classList.add('red');
        $(id3).classList.remove('green');
      }
    }
  }

  // Year Status Update
  updatestatusYear() {
    let id1 = arguments[0].trim() + '_' + arguments[1].trim();
    let id2 = arguments[0].trim() + '_' + arguments[2].trim();

    if (strToNumber($(id1).value) * 1 > 0) {
      $(id2).value = 'Non-compliant';
      $(id2).classList.add('red');
      $(id2).classList.remove('green');
    }
    else {
      $(id2).value = 'Compliant';
      $(id2).classList.add('green');
      $(id2).classList.remove('red');
    }
  }

  // Select Status Update
  updatestatusSelect() {
    let id1 = arguments[0].trim() + '_' + arguments[1].trim();
    let id2 = arguments[0].trim() + '_' + arguments[2].trim();
    if ($(id1).value === 'Per unit as per metered consumption') {
      $(id2).value = 'Compliant';
      $(id2).classList.add('green');
      $(id2).classList.remove('red');
    }
    else {
      $(id2).value = 'Non-compliant';
      $(id2).classList.add('red');
      $(id2).classList.remove('green');
    }
  }

  // Select Status Update
  updatestatusPq2StatutoryAuditSelect() {
    let id1 = 'pqsop_details_pq2_statutory_audit_status';
    if ($(id1).value === 'Completed') {
      $('pqsop_details_pq2_statutory_audit_date').removeAttribute("readonly");
      $("pqsop_details_pq2_board_approval_status").removeAttribute("disabled");
      $("pqsop_details_pq2_cag_status").removeAttribute("disabled");
      $("pqsop_details_pq2_agm_status").removeAttribute("disabled");
    } else {
      $('pqsop_details_pq2_statutory_audit_date').setAttribute('readOnly', 'true');
      $('pqsop_details_pq2_board_approval_date').setAttribute('readOnly', 'true');
      $('pqsop_details_pq2_cag_date').setAttribute('readOnly', 'true');
      $('pqsop_details_pq2_agm_date').setAttribute('readOnly', 'true');
      $("pqsop_details_pq2_board_approval_status").setAttribute("disabled", true);
      $("pqsop_details_pq2_cag_status").setAttribute("disabled", true);
      $("pqsop_details_pq2_agm_status").setAttribute("disabled", true);
      $("pqsop_details_pq2_board_approval_status").value = "";
      $("pqsop_details_pq2_cag_status").value = "";
      $("pqsop_details_pq2_agm_status").value = "";
      $("pqsop_details_pq2_statutory_audit_date").value = "";
      $("pqsop_details_pq2_board_approval_date").value = "";
      $("pqsop_details_pq2_cag_date").value = "";
      $("pqsop_details_pq2_agm_date").value = "";
    }

  }

  updatestatusPq2boardApprovalStatusSelect() {

    let id1 = 'pqsop_details_pq2_board_approval_status';
    if ($(id1).value === 'Completed') {
      $('pqsop_details_pq2_board_approval_date').removeAttribute("readonly");
    } else {
      $('pqsop_details_pq2_board_approval_date').setAttribute('readOnly', 'true');
      $("pqsop_details_pq2_board_approval_date").value = "";
    }
  }

  updatestatusPq2cagStatusSelect() {

    let id1 = 'pqsop_details_pq2_cag_status';
    if ($(id1).value === 'Completed') {
      $('pqsop_details_pq2_cag_date').removeAttribute("readonly");
    } else {
      $('pqsop_details_pq2_cag_date').setAttribute('readOnly', 'true');
      $("pqsop_details_pq2_cag_date").value = "";
    }
  }

  updatestatusPq2agmStatusSelect() {

    let id1 = 'pqsop_details_pq2_agm_status';
    if ($(id1).value === 'Completed') {
      $('pqsop_details_pq2_agm_date').removeAttribute("readonly");
    } else {
      $('pqsop_details_pq2_agm_date').setAttribute('readOnly', 'true');
      $("pqsop_details_pq2_agm_date").value = "";
    }
  }

  updatestatusPopup() {
    let id1 = arguments[0].trim() + '_' + arguments[1].trim();
    let id2 = arguments[0].trim() + '_' + arguments[2].trim();
    if (strToNumber($(id1).value) * 1 > 0) {
      $(id2).value = 'Non-compliant';
      $(id2).classList.add('red');
      $(id2).classList.remove('green');
    }
    else {
      $(id2).value = 'Compliant';
      $(id2).classList.add('green');
      $(id2).classList.remove('red');
    }
  }

  discomsmmajorcomponents() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let optionId = 'sl_smart_metering_discom_sl_discom_item_particulars_' + optionIdsplit[8] + '_1';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getsmsubcomponents/?sm_major_components_id=' + $(selectId).value)
        .then((result) => {
          $(optionId).innerText = null;
          let option = document.createElement("option");
          option.text = 'Select';
          $(optionId).add(option);
          for (let opt in result.smsubcomponents) {
            const optData = result.smsubcomponents[opt];
            let option = document.createElement("option");
            option.text = optData.sub_component_title;
            option.value = optData.id;
            $(optionId).add(option);
          }
        })
        .catch((error) => {
        })
    })();

  }

  districtsmmajorcomponents() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let optionId = 'sl_smart_metering_district_sl_district_item_particulars_' + optionIdsplit[8] + '_1';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getsmsubcomponents/?sm_major_components_id=' + $(selectId).value)
        .then((result) => {
          $(optionId).innerText = null;
          let option = document.createElement("option");
          option.text = 'Select';
          $(optionId).add(option);
          for (let opt in result.smsubcomponents) {
            const optData = result.smsubcomponents[opt];
            let option = document.createElement("option");
            option.text = optData.sub_component_title;
            option.value = optData.id;
            $(optionId).add(option);
          }
        })
        .catch((error) => {
        })
    })();
  }

  lsdiscomsmmajorcomponents() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let optionId = 'sl_loss_reduction_discom_sl_discom_sub_component_' + optionIdsplit[8] + '_1';
    let optionId2 = 'sl_loss_reduction_discom_sl_discom_items_specification_' + optionIdsplit[8] + '_2';
    let unitId = 'sl_loss_reduction_discom_sl_discom_quantity_phase_2_' + optionIdsplit[8] + '_4';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getlssubcomponents/?sm_major_components_id=' + $(selectId).value)
        .then((result) => {
          $(optionId2).innerText = null;
          let option2 = document.createElement("option");
          option2.text = 'Select';
          $(optionId2).add(option2);
          $(optionId).innerText = null;
          let option = document.createElement("option");
          option.text = 'Select';
          $(optionId).add(option);
          $(unitId).value = '';
          for (let opt in result.lssubcomponents) {
            const optData = result.lssubcomponents[opt];
            let option = document.createElement("option");
            option.text = optData.title;
            option.value = optData.id;
            $(optionId).add(option);
          }
        })
        .catch((error) => {
        })
    })();
  }

  lsdiscomsitemspecification() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let optionId = 'sl_loss_reduction_discom_sl_discom_items_specification_' + optionIdsplit[8] + '_2';
    let unitId = 'sl_loss_reduction_discom_sl_discom_quantity_phase_2_' + optionIdsplit[8] + '_4';

    if ($(selectId).value === 'Select') {
      $(optionId).innerText = null;
      let option = document.createElement("option");
      option.text = 'Select';
      $(optionId).add(option);
      $(unitId).value = '';
    } else {
      (async () => {
        await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getlsitemsspecification/?sm_major_components_id=' + $(selectId).value)
          .then((result) => {
            $(optionId).innerText = null;
            let option = document.createElement("option");
            option.text = 'Select';
            $(optionId).add(option);
            for (let opt in result.lssubcomponents) {
              const optData = result.lssubcomponents[opt];
              let option = document.createElement("option");
              option.text = optData.title;
              option.value = optData.id;
              $(optionId).add(option);
              $(unitId).value = result.unit;
            }
          })
          .catch((error) => {
          })
      })();
    }

  }

  lsdistrictmajorcomponents() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let optionId = 'sl_loss_reduction_district_sl_district_sub_component_' + optionIdsplit[8] + '_1';
    let optionId2 = 'sl_loss_reduction_district_sl_district_items_specification_' + optionIdsplit[8] + '_2';
    let unitId = 'sl_loss_reduction_district_sl_district_quantity_phase_2_' + optionIdsplit[8] + '_4';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getlssubcomponents/?sm_major_components_id=' + $(selectId).value)
        .then((result) => {
          $(optionId2).innerText = null;
          let option2 = document.createElement("option");
          option2.text = 'Select';
          $(optionId2).add(option2);
          $(optionId).innerText = null;
          let option = document.createElement("option");
          option.text = 'Select';
          $(optionId).add(option);
          $(unitId).value = '';
          for (let opt in result.lssubcomponents) {
            const optData = result.lssubcomponents[opt];
            let option = document.createElement("option");
            option.text = optData.title;
            option.value = optData.id;
            $(optionId).add(option);
          }
        })
        .catch((error) => {
        })
    })();
  }

  lsdistrictitemspecification() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');

    let optionId = 'sl_loss_reduction_district_sl_district_items_specification_' + optionIdsplit[8] + '_2';
    let unitId = 'sl_loss_reduction_district_sl_district_quantity_phase_2_' + optionIdsplit[8] + '_4';

    if ($(selectId).value === 'Select') {
      $(optionId).innerText = null;
      let option = document.createElement("option");
      option.text = 'Select';
      $(optionId).add(option);
      $(unitId).value = '';
    } else {
      (async () => {
        await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getlsitemsspecification/?sm_major_components_id=' + $(selectId).value)
          .then((result) => {
            $(optionId).innerText = null;
            let option = document.createElement("option");
            option.text = 'Select';
            $(optionId).add(option);
            for (let opt in result.lssubcomponents) {
              const optData = result.lssubcomponents[opt];
              let option = document.createElement("option");
              option.text = optData.title;
              option.value = optData.id;
              $(optionId).add(option);
              $(unitId).value = result.unit;
            }
          })
          .catch((error) => {
          })
      })();

    }
  }

  requestforloadate() {
    let selectId = arguments[3];
    let optionIdsplit = selectId.split('_');
    let form_id = arguments[2].trim();
    let dicom_id = arguments[1].trim();

    let optionId = 'fd_request_for_fund_release_project_management_agency_name_' + optionIdsplit[optionIdsplit.length - 2] + '_0';
    let loadate = 'fd_request_for_fund_release_pma_loa_date_' + optionIdsplit[optionIdsplit.length - 2] + '_1';

    if ($(optionId).value === 'Select') {
      $(loadate).value = '';
    } else {
      (async () => {

        let biderNameString = btoa($(optionId).value);
        await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-loa-date/?bidder_name=' + biderNameString + '&discom_id=' + dicom_id + '&form_id=' + form_id)
          .then((result) => {
            $(loadate).value = result.date_of_loa;
          })
          .catch((error) => {
          })
      })();

    }
  }

  pqforcurrentyearcal() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');

    let id1 = "pqsop_details_pq4_sub_previous_builed_" + optionIdsplit[6] + "_0";
    let id2 = "pqsop_details_pq4_sub_previous_recived_" + optionIdsplit[6] + "_2";
    let id3 = "pqsop_details_pq4_sub_previous_deficit_" + optionIdsplit[6] + "_4";
    let id4 = "pqsop_details_pq4_sub_previous_deficit_total";
    let id5 = "pqsop_details_pq4_sub_previous_status";

    if (id1 && id2) {
      let decimal3 = $(id3).hasAttribute('data-amount') ? 2 : 0;
      if ($(id3).hasAttribute('data-decimal')) decimal3 = $(id3).getAttribute('data-decimal');
      $(id3).value = toFixed(strToNumber($(id1).value) * 1 - strToNumber($(id2).value) * 1, decimal3);
    }



    if (($(id4).value)) {
      if ((strToNumber($(id4).value) * 1) > 0) {
        $(id5).value = 'Non-compliant';
        $(id5).classList.add('red');
        $(id5).classList.remove('green');
      }
      else {
        $(id5).value = 'Compliant';
        $(id5).classList.add('green');
        $(id5).classList.remove('red');
      }
    }
    else {
      $(id5).value = '';
    }

    pq4allstatus();
  }

  pqforadvancesubsidycal() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');

    let id1 = "pqsop_details_pq4_sub_current_builed_" + optionIdsplit[6] + "_0";
    let id2 = "pqsop_details_pq4_sub_current_recived_" + optionIdsplit[6] + "_2";
    let id3 = "pqsop_details_pq4_sub_current_deficit_" + optionIdsplit[6] + "_4";
    let id4 = "pqsop_details_pq4_sub_current_deficit_total";
    let id5 = "pqsop_details_pq4_sub_current_status";

    if (id1 && id2) {
      let decimal3 = $(id3).hasAttribute('data-amount') ? 2 : 0;
      if ($(id3).hasAttribute('data-decimal')) decimal3 = $(id3).getAttribute('data-decimal');
      $(id3).value = toFixed(strToNumber($(id1).value) * 1 - strToNumber($(id2).value) * 1, decimal3);
    }

    if ($(id4).value) {
      if (($(id4).value * 1) > 0) {
        $(id5).value = 'Non-compliant';
        $(id5).classList.add('red');
        $(id5).classList.remove('green');
      }
      else {
        $(id5).value = 'Compliant';
        $(id5).classList.add('green');
        $(id5).classList.remove('red');
      }
    }
    else {
      $(id5).value = '';
    }

    pq4allstatus();
  }

  fddistrictprojectchange() {
    let selectId = arguments[3];
    let discom_id = parseInt(arguments[1]);
    let component_id = parseInt(arguments[2]);
    let optionIdsplit = selectId.split('_');
    let targetelementId = 'fd_physical_progress_fd_grant_no_' + optionIdsplit[6] + '_1';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getgrantnobydistrictproject/?fd_district_project_id=' + ($(selectId).value) + '&discom_id=' + discom_id + '&component_id=' + component_id)
        .then((result) => {
          $(targetelementId).value = result.grant_no_scheme_code;
        })
        .catch((error) => {
        })
    })();
  }

  getYearWaisGrantNo() {
    let selectId = arguments[3];
    let discom_id = parseInt(arguments[1]);
    let component_id = parseInt(arguments[2]);
    let optionIdsplit = selectId.split('_');

    let schemeCodetId = 'fd_physical_progress_' + optionIdsplit[3] + '_fd_grant_no_' + optionIdsplit[7] + '_1';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-year-wais-grant-no/?fd_district_project_id=' + ($(selectId).value) + '&discom_id=' + discom_id + '&component_id=' + component_id)
        .then((result) => {
          $(schemeCodetId).value = result.grant_no_scheme_code;
        })
        .catch((error) => {
        })
    })();
  }

  goiformupdategrantno() {
    let selectId = arguments[3];
    let discom_id = parseInt(arguments[1]);
    let component_id = parseInt(arguments[2]);
    let optionIdsplit = selectId.split('_');
    let targetelementId = 'fd_financial_progress_sm_sanction_letter_entr_' + optionIdsplit[6] + '_1';    // default Smart Metering Works
    if (component_id === 2) {    // Loss Reduction Works
      targetelementId = 'fd_financial_progress_lr_sanction_letter_entr_' + optionIdsplit[6] + '_1';
    }
    if (component_id === 3) {    // Modernization Works
      targetelementId = 'fd_financial_progress_mode_sanction_letter_entr_' + optionIdsplit[6] + '_1';
    }

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getgrantnobydistrictproject/?fd_district_project_id=' + ($(selectId).value) + '&discom_id=' + discom_id + '&component_id=' + component_id)
        .then((result) => {
          $(targetelementId).value = result.grant_no_scheme_code;
        })
        .catch((error) => {
        })
    })();
  }

  cfformupdategrantno() {
    let selectId = arguments[3];
    let discom_id = parseInt(arguments[1]);
    let component_id = parseInt(arguments[2]);
    let optionIdsplit = selectId.split('_');
    let targetelementId = 'sm_cf_fund_disbursal_cf_sm_ggn_sc_' + optionIdsplit[optionIdsplit.length - 2] + '_1';    // default Smart Metering Works
    if (component_id === 2) {    // Loss Reduction Works
      targetelementId = 'sm_cf_fund_disbursal_sm_cf_lr_ggn_sc_' + optionIdsplit[optionIdsplit.length - 2] + '_1';
    }
    if (component_id === 3) {    // Modernization Works
      targetelementId = 'sm_cf_fund_disbursal_sm_cf_mo_ggn_sc_' + optionIdsplit[optionIdsplit.length - 2] + '_1';
    }

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getgrantnobydistrictproject/?fd_district_project_id=' + ($(selectId).value) + '&discom_id=' + discom_id + '&component_id=' + component_id)
        .then((result) => {
          $(targetelementId).value = result.grant_no_scheme_code;
        })
        .catch((error) => {
        })
    })();
  }

  referencesanctionletterno() {

    let selectId = arguments[3];
    let discom_id = parseInt(arguments[1]);
    let application_id = parseInt(arguments[2]);
    let component_id = $(selectId).value;
    let optionIdsplit = selectId.split('_');

    let letterDate = 'fd_utilisation_forms_gfr_c_gfr_c_reference_sanction_letter_date_' + optionIdsplit[optionIdsplit.length - 2] + '_1';
    let amountDisbursedIn = 'fd_utilisation_forms_gfr_c_gfr_c_amount_' + optionIdsplit[optionIdsplit.length - 2] + '_2';
    let cTotalUnspentBalance = 'fd_utilisation_forms_gfr_c_gfr_c_total_unspent_balance_' + optionIdsplit[optionIdsplit.length - 2] + '_3';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/referencesanctionletterno/?discom_id=' + discom_id + '&component_id=' + component_id + '&application_id=' + application_id)
        .then((result) => {
          $(letterDate).value = result.letter_date;
          $(amountDisbursedIn).value = result.amount_disbursed_in;
          $(cTotalUnspentBalance).value = result.gfr_c_total_unspent_balance;
        })
        .catch((error) => {
        }).finally(() => {
        })
    })();
  }

  referencesanctionletternoyearly() {

    let selectId = arguments[3];
    let discom_id = parseInt(arguments[1]);
    let year = parseInt(arguments[2]);
    let component_id = $(selectId).value;
    let optionIdsplit = selectId.split('_');

    let letterDate = 'fd_utilisation_forms_gfr_c_gfr_c_reference_sanction_letter_date_' + optionIdsplit[optionIdsplit.length - 2] + '_1';
    let amountDisbursedIn = 'fd_utilisation_forms_gfr_c_gfr_c_amount_' + optionIdsplit[optionIdsplit.length - 2] + '_2';
    let cTotalUnspentBalance = 'fd_utilisation_forms_gfr_c_gfr_c_total_unspent_balance_' + optionIdsplit[optionIdsplit.length - 2] + '_3';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/referencesanctionletternoyearly/?discom_id=' + discom_id + '&component_id=' + component_id + '&year=' + year)
        .then((result) => {
          $(letterDate).value = result.letter_date;
          $(amountDisbursedIn).value = result.amount_disbursed_in;
          $(cTotalUnspentBalance).value = result.gfr_c_total_unspent_balance;
        })
        .catch((error) => {
        }).finally(() => {
        })
    })();
  }

  updategfragrantspositiondata() {
    let selectId = arguments[3];
    let selectedyear = $(selectId).value;
    let discom_id = parseInt(arguments[1]);
    let component_id = parseInt(arguments[2]);

    if (selectedyear == 0) {
      $('fd_utilisation_forms_gfr_a_gfr_a_utilisation_upto_date').value = '';
      $('fd_utilisation_forms_gfr_a_gfr_a_utilisation_upto_date').setAttribute("max", '');
    } else {
      $('fd_utilisation_forms_gfr_a_gfr_a_utilisation_upto_date').value = '';
      $('fd_utilisation_forms_gfr_a_gfr_a_utilisation_upto_date').setAttribute("max", selectedyear + '-03-31');
    }

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getgfragrantspositiondata/?selected_year=' + selectedyear + '&discom_id=' + discom_id + '&component_id=' + component_id)
        .then((result) => {
          $('fd_utilisation_forms_gfr_a_grants_position_cash_in_hand_bank').value = result.grants_position_cash_in_hand_bank;
          $('fd_utilisation_forms_gfr_a_grants_position_unadjusted_advances').value = result.grants_position_unadjusted_advances;
          $('fd_utilisation_forms_gfr_a_grants_position_total').value = (strToNumber(result.grants_position_cash_in_hand_bank) * 1) + (strToNumber(result.grants_position_unadjusted_advances) * 1);
          $('fd_utilisation_forms_gfr_a_gfr_a_unspent_balance_0_0').value = (strToNumber(result.grants_position_cash_in_hand_bank) * 1) + (strToNumber(result.grants_position_unadjusted_advances) * 1);
          //this.updatetotalavailablefundvalue();
        })
        .catch((error) => {
        })
    })();
  }

  updategfragrantspositiondatayearly() {
    let selectId = arguments[3];
    let selectedyear = $(selectId).value;
    let discom_id = parseInt(arguments[1]);

    if (selectedyear == 0) {
      $('fd_utilisation_forms_gfr_a_gfr_a_utilisation_upto_date').value = '';
      $('fd_utilisation_forms_gfr_a_gfr_a_utilisation_upto_date').setAttribute("max", '');
    } else {
      $('fd_utilisation_forms_gfr_a_gfr_a_utilisation_upto_date').value = '';
      $('fd_utilisation_forms_gfr_a_gfr_a_utilisation_upto_date').setAttribute("max", selectedyear + '-03-31');
    }

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/getgfragrantspositiondatayearly/?selected_year=' + selectedyear + '&discom_id=' + discom_id)
        .then((result) => {
          $('fd_utilisation_forms_gfr_a_grants_position_cash_in_hand_bank').value = result.grants_position_cash_in_hand_bank;
          $('fd_utilisation_forms_gfr_a_grants_position_unadjusted_advances').value = result.grants_position_unadjusted_advances;
          $('fd_utilisation_forms_gfr_a_grants_position_total').value = (strToNumber(result.grants_position_cash_in_hand_bank) * 1) + (strToNumber(result.grants_position_unadjusted_advances) * 1);
          $('fd_utilisation_forms_gfr_a_gfr_a_unspent_balance_0_0').value = (strToNumber(result.grants_position_cash_in_hand_bank) * 1) + (strToNumber(result.grants_position_unadjusted_advances) * 1);
          //this.updatetotalavailablefundvalue();
        })
        .catch((error) => {
        })
    })();
  }

  nameOfMateria() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let optionId = 'pre_dispatch_inspection_details_material_inspected_' + optionIdsplit[6] + '_2';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-material-inspected/?material_inspected_id=' + $(selectId).value)
        .then((result) => {
          $(optionId).innerText = null;
          let option = document.createElement("option");
          option.text = 'Select';
          $(optionId).add(option);
          for (let opt in result.materialinspectedname) {
            const optData = result.materialinspectedname[opt];
            let option = document.createElement("option");
            option.text = optData.material_inspected_name;
            option.value = optData.id;
            $(optionId).add(option);
          }
        })
        .catch((error) => {
        })
    })();

  }

  qtNameOfMateria() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let optionId = 'qa_at_site_fields_details_material_inspected_' + optionIdsplit[7] + '_3';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-material-inspected/?material_inspected_id=' + $(selectId).value)
        .then((result) => {
          $(optionId).innerText = null;
          let option = document.createElement("option");
          option.text = 'Select';
          $(optionId).add(option);
          for (let opt in result.materialinspectedname) {
            const optData = result.materialinspectedname[opt];
            let option = document.createElement("option");
            option.text = optData.material_inspected_name;
            option.value = optData.id;
            $(optionId).add(option);
          }
        })
        .catch((error) => {
        })
    })();

  }

  dateofcompletion() {
    let selectId = arguments[3];
    let optionIdsplit = selectId.split('_');
    let discomId = arguments[1].trim();
    let districtId = arguments[2].trim();
    let dateofCompletion = $(arguments[3].trim()).value;
    let lotNumberId = 'qa_at_site_fields_lot_number_' + optionIdsplit[7] + '_8';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/qa-lot-number/?date_of_completion=' + dateofCompletion + '&discom_id=' + discomId + '&district_id=' + districtId)
        .then((result) => {
          $(lotNumberId).innerText = null;
          let option = document.createElement("option");
          option.text = 'Select';
          $(lotNumberId).add(option);
          for (let opt in result.lot_number) {
            const optData = result.lot_number[opt];
            let option = document.createElement("option");
            option.text = optData;
            option.value = optData;
            $(lotNumberId).add(option);
          }
        })
        .catch((error) => {
        })
    })();
  }

  lotnumber() {
    let selectId = arguments[3];
    let optionIdsplit = selectId.split('_');
    let discomId = arguments[1].trim();
    let districtId = arguments[2].trim();

    let lotdateofCompletionId = 'qa_at_site_fields_date_comp_test_' + optionIdsplit[6] + '_0';
    let lotNumberId = 'qa_at_site_fields_lot_number_' + optionIdsplit[6] + '_8';
    let lotSizeId = 'qa_at_site_fields_lot_size_' + optionIdsplit[6] + '_9';
    let sampleMaterialUniqueId = 'qa_at_site_fields_sample_material_unique_' + optionIdsplit[6] + '_10';

    let dateofCompletion = $(lotdateofCompletionId).value;
    let lotNumber = $(lotNumberId).value;

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/qa-size-number/?date_of_completion=' + dateofCompletion + '&discom_id=' + discomId + '&district_id=' + districtId + '&lot_number=' + lotNumber)
        .then((result) => {
          if (result._resultflag) {
            $(lotSizeId).value = result.lot_size;
            $(sampleMaterialUniqueId).value = result.sample_material_unique;
          }
        })
        .catch((error) => {
        })
    })();
  }

  qtFirstSampleStatus() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let firstSampleId = arguments[2].trim();
    let firstSampleValue = $(firstSampleId).value;

    let secondSampleId = 'pre_dispatch_inspection_second_sample_' + optionIdsplit[5].trim() + '_17';
    let statusId = 'pre_dispatch_inspection_lov_accepted_rejected_' + optionIdsplit[5].trim() + '_18';

    if (firstSampleValue == 'Passed') {
      $(statusId).value = 'Accepted';
      $(secondSampleId).value = '';
      $(secondSampleId).innerText = null;
      let option = document.createElement("option");
      option.text = 'Select';
      $(secondSampleId).add(option);
    } else {
      $(statusId).value = '';
      $(secondSampleId).value = '';
      var result = ["Passed", "Failed"];
      $(secondSampleId).innerText = null;
      let option = document.createElement("option");
      option.text = 'Select';
      $(secondSampleId).add(option);
      for (let opt in result) {
        const optData = result[opt];
        let option = document.createElement("option");
        option.text = optData;
        option.value = optData;
        $(secondSampleId).add(option);
      }
    }
  }

  qtSecondSampleStatus() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let secondSampleId = arguments[2].trim();
    let qtSecondSampleStatus = $(secondSampleId).value;

    let statusId = 'pre_dispatch_inspection_lov_accepted_rejected_' + optionIdsplit[5].trim() + '_18';

    if (qtSecondSampleStatus == 'Passed') {
      $(statusId).value = 'Accepted';
    } else if (qtSecondSampleStatus == 'Failed') {
      $(statusId).value = 'Rejected';
    } else {
      $(statusId).value = '';
    }
  }

  getMeterReadingFormdataUsingFeeders() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_').reverse();
    let zone = 'form_input_energy_zone_' + optionIdsplit[1] + '_0';
    let circle = 'form_input_energy_circle_' + optionIdsplit[1] + '_1';
    let feederid = 'form_input_energy_feeder_id_' + optionIdsplit[1] + '_4';
    let voltagelevel = 'form_input_energy_voltage_level_kva_' + optionIdsplit[1] + '_2';
    let division = 'form_input_energy_divison_kva_' + optionIdsplit[1] + '_3';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-masterfieldsdata?feeder_id=' + $(selectId).value)
        .then((result) => {
          $(zone).innerText = null;
          $(circle).innerText = null;
          $(feederid).value = null;
          $(voltagelevel).value = null;
          $(division).innerText = null;

          for (let optzone in result.listZone) {
            const optData = result.listZone[optzone];
            let option = document.createElement("option");
            option.text = optData;
            option.value = optzone;
            $(zone).add(option);
          }

          for (let circleid in result.listCircle) {
            const circlename = result.listCircle[circleid];
            let circleoption = document.createElement("option");
            circleoption.text = circlename;
            circleoption.value = circleid;
            $(circle).add(circleoption);
          }

          $(feederid).value = result.feederId;

          $(voltagelevel).value = result.voltageValue;

          for (let divisionid in result.listDivision) {
            const divisionname = result.listDivision[divisionid];
            let divisionoption = document.createElement("option");
            divisionoption.text = divisionname;
            divisionoption.value = divisionid;
            $(division).add(divisionoption);
          }
        })
        .catch((error) => {
        })
    })();
  }

  getFeederLevelFormdataUsingFeeders() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_').reverse();
    let zone = 'details_of_feeder_wise_losses_zone_' + optionIdsplit[1] + '_0';
    let circle = 'details_of_feeder_wise_losses_name_of_circles_' + optionIdsplit[1] + '_1';
    let subdivision = 'details_of_feeder_wise_losses_name_sub_division_' + optionIdsplit[1] + '_2';
    let substation = 'details_of_feeder_wise_losses_name_sub_station_' + optionIdsplit[1] + '_4';
    let feederid = 'details_of_feeder_wise_losses_feeder_code_id_' + optionIdsplit[1] + '_5';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-masterfieldsdata?feeder_id=' + $(selectId).value)
        .then((result) => {
          $(zone).innerText = null;
          $(circle).innerText = null;
          $(subdivision).innerText = null;
          $(substation).innerText = null;
          $(feederid).value = null;

          for (let optzone in result.listZone) {
            const optData = result.listZone[optzone];
            let option = document.createElement("option");
            option.text = optData;
            option.value = optzone;
            $(zone).add(option);
          }

          for (let circleid in result.listCircle) {
            const circlename = result.listCircle[circleid];
            let circleoption = document.createElement("option");
            circleoption.text = circlename;
            circleoption.value = circleid;
            $(circle).add(circleoption);
          }

          for (let divisionid in result.listSubDivision) {
            const divisionname = result.listSubDivision[divisionid];
            let divisionoption = document.createElement("option");
            divisionoption.text = divisionname;
            divisionoption.value = divisionid;
            $(subdivision).add(divisionoption);
          }

          for (let substationid in result.listSubStation) {
            const substationname = result.listSubStation[substationid];
            let substationoption = document.createElement("option");
            substationoption.text = substationname;
            substationoption.value = substationid;
            $(substation).add(substationoption);
          }

          $(feederid).value = result.feederId;
        })
        .catch((error) => {
        })
    })();
  }

  getDTWiseLossesFormdataUsingFeeders() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_').reverse();
    let zone = 'details_of_dt_wise_losses_zone_name_' + optionIdsplit[1] + '_0';
    let circle = 'details_of_dt_wise_losses_details_of_circle_' + optionIdsplit[1] + '_1';
    let division = 'details_of_dt_wise_losses_details_of_div_' + optionIdsplit[1] + '_2';
    let subdivision = 'details_of_dt_wise_losses_details_of_sub_div_' + optionIdsplit[1] + '_3';
    let substation = 'details_of_dt_wise_losses_details_of_sub_station_' + optionIdsplit[1] + '_4';
    let substationCode = 'details_of_dt_wise_losses_substation_code_' + optionIdsplit[1] + '_5';
    let feederid = 'details_of_dt_wise_losses_feeder_code_' + optionIdsplit[1] + '_7';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-masterfieldsdata?feeder_id=' + $(selectId).value)
        .then((result) => {
          $(zone).innerText = null;
          $(circle).innerText = null;
          $(division).innerText = null;
          $(subdivision).innerText = null;
          $(substation).innerText = null;
          $(substationCode).value = null;
          $(feederid).value = null;

          for (let optzone in result.listZone) {
            const optData = result.listZone[optzone];
            let option = document.createElement("option");
            option.text = optData;
            option.value = optzone;
            $(zone).add(option);
          }

          for (let circleid in result.listCircle) {
            const circlename = result.listCircle[circleid];
            let circleoption = document.createElement("option");
            circleoption.text = circlename;
            circleoption.value = circleid;
            $(circle).add(circleoption);
          }

          for (let divisionid in result.listDivision) {
            const divisionname = result.listDivision[divisionid];
            let divisionoption = document.createElement("option");
            divisionoption.text = divisionname;
            divisionoption.value = divisionid;
            $(division).add(divisionoption);
          }

          for (let subdivisionid in result.listSubDivision) {
            const subdivisionname = result.listSubDivision[subdivisionid];
            let subdivisionoption = document.createElement("option");
            subdivisionoption.text = subdivisionname;
            subdivisionoption.value = subdivisionid;
            $(subdivision).add(subdivisionoption);
          }

          for (let substationid in result.listSubStation) {
            const substationname = result.listSubStation[substationid];
            let substationoption = document.createElement("option");
            substationoption.text = substationname;
            substationoption.value = substationid;
            $(substation).add(substationoption);
          }

          $(substationCode).value = result.substation_code;

          $(feederid).value = result.feederId;
        })
        .catch((error) => {
        })
    })();
  }

  getDTLevelMonitoringdataUsingFeeders() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_').reverse();
    // Division Wise Status of DT Level Monitoring
    let zoneLevel = 'details_of_dt_level_monitoring_zone_name_' + optionIdsplit[1] + '_0';
    let circleLevel = 'details_of_dt_level_monitoring_circle_name_' + optionIdsplit[1] + '_1';
    let subdivisionLevel = 'details_of_dt_level_monitoring_sub_division_name_' + optionIdsplit[1] + '_2';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-masterfieldsdata?feeder_id=' + $(selectId).value)
        .then((result) => {
          $(zoneLevel).innerText = null;
          $(circleLevel).innerText = null;
          $(subdivisionLevel).innerText = null;

          for (let optzone in result.listZone) {
            const optData = result.listZone[optzone];
            let option2 = document.createElement("option");
            option2.text = optData;
            option2.value = optzone;
            $(zoneLevel).add(option2);
          }

          for (let circleid in result.listCircle) {
            const circlename = result.listCircle[circleid];
            let circleoption2 = document.createElement("option");
            circleoption2.text = circlename;
            circleoption2.value = circleid;
            $(circleLevel).add(circleoption2);
          }

          for (let subdivisionid in result.listSubDivision) {
            const subdivisionname = result.listSubDivision[subdivisionid];
            let subdivisionoption2 = document.createElement("option");
            subdivisionoption2.text = subdivisionname;
            subdivisionoption2.value = subdivisionid;
            $(subdivisionLevel).add(subdivisionoption2);
          }
        })
        .catch((error) => {
        })
    })();
  }

  getDivison() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_').reverse();
    let divison = 'form_input_energy_divison_kva_' + optionIdsplit[1] + '_3';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/get-divison?circle_id=' + $(selectId).value)
        .then((result) => {
          $(divison).innerText = null;

          for (let optzone in result.listDivision) {
            const optData = result.listDivision[optzone];
            let option2 = document.createElement("option");
            option2.text = optData;
            option2.value = optzone;
            $(divison).add(option2);
          }
        })
        .catch((error) => {
        })
    })();
  }

  frSmGrantNo() {
    let discom_id = parseInt(arguments[1]);
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');

    let grantNo = 'sm_' + optionIdsplit[1] + '_district_fund_released_sm_' + optionIdsplit[1] + '_grant_no_' + optionIdsplit[9] + '_1';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/fr-sm-grant-code-no/?district_id=' + ($(selectId).value) + '&discom_id=' + discom_id)
        .then((result) => {
          $(grantNo).value = result.grant_no_scheme_code;
        })
        .catch((error) => {
        })
    })();
  }

  frLrGrantNo() {
    let discom_id = parseInt(arguments[1]);
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');

    let grantNo = 'lr_' + optionIdsplit[1] + '_district_fund_released_lr_' + optionIdsplit[1] + '_grant_no_' + optionIdsplit[9] + '_1';

    (async () => {
      await Http.get(process.env.REACT_APP_API_ENDPOINT + '/apiv1/fr-lr-grant-code-no/?district_id=' + ($(selectId).value) + '&discom_id=' + discom_id)
        .then((result) => {
          $(grantNo).value = result.grant_no_scheme_code;
        })
        .catch((error) => {
        })
    })();
  }

  // Award Module > LR
  awardModulePresentStatus() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let lrPrefix = 'lr_award_module';
    if (ele.value === '1') {
      // RFP
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'N');
      // Technical
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'N');
      // Financial
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'N');
      // Participants
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).value = '';
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');
      // Successful bidder
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
      // File Upload
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '2') {
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');
      let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
        }
      }
      // ========== Remove Validation
      // Technical
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'N');
      // Financial
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'N');
      // Participants
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).value = '';
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');
      // Successful bidder
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
      // File Upload
      //document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '3') {
      // RFP
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');
      let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
        }
      }
      // Technical
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'Y');
      let technicalDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`);
      if (technicalDateEle) {
        if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
        }
      }
      // ========== Remove Validation
      // Financial
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'N');
      // Participants
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).value = '';
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');
      // Successful bidder
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
      // File Upload
      //document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
      //document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '4') {
      // RFP
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');
      let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
        }
      }
      // Technical
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'Y');
      let technicalDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`);
      if (technicalDateEle) {
        if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
        }
      }
      // Financial
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'Y');
      let financialDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`);
      if (financialDateEle) {
        if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
        }
      }
      // ========== Remove Validation
      // Participants
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).value = '';
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');
      // Successful bidder
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
      // File Upload
      //document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
      //document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
      //document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '5') {
      // RFP
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');
      let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
        }
      }
      // Technical
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'Y');
      let technicalDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`);
      if (technicalDateEle) {
        if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
        }
      }
      // Financial
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'Y');
      let financialDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`);
      if (financialDateEle) {
        if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
        }
      }
      // Participants
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'Y');

      // ========== Remove Validation
      // Successful bidder
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
      // File Upload
      document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '6') {
      // RFP
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');

      // Technical
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'Y');

      // Financial
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'Y');

      // Participants
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'Y');

      // Successful bidder
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'Y');

      // LOA
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'Y');

      let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
        }
      }
      let technicalDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`);
      if (technicalDateEle) {
        if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
        }
      }
      let financialDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`);
      if (financialDateEle) {
        if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
        }
      }
      let loaDateEle = document.getElementById(`${lrPrefix}lr_award_module_lr_actual_date_of_loa_${optionIdSplit[6]}_18`);
      if (loaDateEle) {
        if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'N');
        }
      }
    }
    else if (ele.value === '7' || ele.value === '8') {
      // RFP
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'N');

      // Technical
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'N');

      // Financial
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'N');

      // Participants
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');

      // Successful bidder
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');

      // LOA
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).removeAttribute("readonly");
      document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');

      // File Upload
      document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
      document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'N');
    }
    let frmID = ele.closest("form");
    validatefrm();
  }

  actualRfpChange() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let presentStatusEle = document.getElementById(`lr_award_module_lr_present_status_${optionIdSplit[8]}_5`);
    if (ele) {
      if (ele.value !== '' && presentStatusEle.value !== '7' && presentStatusEle.value !== '8') {
        document.getElementById(`lr_award_module_lr_actual_rfp_issue_upload_${optionIdSplit[8]}_8`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`lr_award_module_lr_actual_rfp_issue_upload_${optionIdSplit[8]}_8`).setAttribute('data-required', 'N');
      }
    }
  }

  actualTechnicalChange() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let presentStatusEle = document.getElementById(`lr_award_module_lr_present_status_${optionIdSplit[10]}_5`);
    if (ele) {
      if (ele.value !== '' && presentStatusEle.value !== '7' && presentStatusEle.value !== '8') {
        document.getElementById(`lr_award_module_lr_actual_technical_bid_opening_upload_${optionIdSplit[10]}_12`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`lr_award_module_lr_actual_technical_bid_opening_upload_${optionIdSplit[10]}_12`).setAttribute('data-required', 'N');
      }
    }
  }

  actualFinancialChange() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let presentStatusEle = document.getElementById(`lr_award_module_lr_present_status_${optionIdSplit[10]}_5`);
    if (ele) {
      if (ele.value !== '' && presentStatusEle.value !== '7' && presentStatusEle.value !== '8') {
        document.getElementById(`lr_award_module_lr_actual_financial_bid_opening_upload_${optionIdSplit[10]}_14`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`lr_award_module_lr_actual_financial_bid_opening_upload_${optionIdSplit[10]}_14`).setAttribute('data-required', 'N');
      }
    }
  }

  actualLoaChange() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let presentStatusEle = document.getElementById(`lr_award_module_lr_present_status_${optionIdSplit[8]}_5`);
    if (ele) {
      if (ele.value !== '' && presentStatusEle.value !== '7' && presentStatusEle.value !== '8') {
        document.getElementById(`lr_award_module_lr_actual_loa_upload_${optionIdSplit[8]}_19`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`lr_award_module_lr_actual_loa_upload_${optionIdSplit[8]}_19`).setAttribute('data-required', 'N');
      }
    }
  }

  actualContractChange() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    if (ele) {
      if (ele.value !== '') {
        document.getElementById(`lr_award_module_lr_contract_agreement_upload_${optionIdSplit[7]}_21`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`lr_award_module_lr_contract_agreement_upload_${optionIdSplit[7]}_21`).setAttribute('data-required', 'N');
      }
    }
  }

  // Award Module > SM
  awardModulePresentStatusSm() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let smPrefix = 'sm_award_module';
    if (ele.value === '1') {
      // RFP
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'N');
      // Technical
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'N');
      // Financial
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'N');
      // Participants
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).value = '';
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');
      // Successful bidder
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
      // File Upload
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
      document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
      document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
      document.getElementById(`${smPrefix}_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '2') {
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
      let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
        }
      }

      // ========== Remove Validation
      // Technical
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'N');
      // Financial
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'N');
      // Participants
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).value = '';
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');
      // Successful bidder
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
      // File Upload
      document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
      document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
      document.getElementById(`${smPrefix}_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '3') {
      // RFP
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
      let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
        }
      }
      // Technical
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'Y');
      let technicalDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`);
      if (technicalDateEle) {
        if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
        }
      }
      // ========== Remove Validation
      // Financial
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'N');
      // Participants
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).value = '';
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');
      // Successful bidder
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
      // File Upload
      document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
      document.getElementById(`${smPrefix}_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '4') {
      // RFP
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
      let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
        }
      }
      // Technical
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'Y');
      let technicalDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`);
      if (technicalDateEle) {
        if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
        }
      }
      // Financial
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'Y');
      let financialDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`);
      if (financialDateEle) {
        if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
        }
      }

      // ========== Remove Validation
      // Participants
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).value = '';
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');
      // Successful bidder
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
      // File Upload
      document.getElementById(`${smPrefix}_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '5') {
      // RFP
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
      let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
        }
      }
      // Technical
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'Y');
      let technicalDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`);
      if (technicalDateEle) {
        if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
        }
      }
      // Financial
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'Y');
      let financialDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`);
      if (financialDateEle) {
        if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
        }
      }
      // Participants
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'Y');

      // ========== Remove Validation
      // Successful bidder
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
      // File Upload
      document.getElementById(`${smPrefix}_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'N');
    }
    else if (ele.value === '6') {
      // RFP
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
      // Technical
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'Y');
      // Financial
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'Y');
      // Participants
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'Y');
      // Successful bidder
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'Y');
      // LOA
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'Y');

      let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
      if (rfpDateEle) {
        if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
        }
      }
      let technicalDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`);
      if (technicalDateEle) {
        if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
        }
      }
      let financialDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`);
      if (financialDateEle) {
        if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
        }
      }
      let loaDateEle = document.getElementById(`${smPrefix}sm_award_module_sm_actual_loa_upload_${optionIdSplit[6]}_5_1`);
      if (loaDateEle) {
        if (loaDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
          document.getElementById(`${smPrefix}sm_award_module_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'Y');
        } else {
          document.getElementById(`${smPrefix}sm_award_module_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'N');
        }
      }
    }
    else if (ele.value === '7' || ele.value === '8') {
      // RFP
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'N');
      // Technical
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'N');
      // Financial
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'N');
      // Participants
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');
      // Successful bidder
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
      // LOA
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).removeAttribute("readonly");
      document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
      // File Upload
      document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
      document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
      document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
      document.getElementById(`${smPrefix}_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'N');
    }
    validatefrm();
  }

  actualRfpChangeSm() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let presentStatusEle = document.getElementById(`sm_award_module_sm_present_status_${optionIdSplit[8]}_4_0`);
    if (ele) {
      if (ele.value !== '' && presentStatusEle.value !== '7' && presentStatusEle.value !== '8') {
        document.getElementById(`sm_award_module_sm_actual_rfp_issue_upload_${optionIdSplit[8]}_4_3`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`sm_award_module_sm_actual_rfp_issue_upload_${optionIdSplit[8]}_4_3`).setAttribute('data-required', 'N');
      }
    }
  }

  actualTechnicalChangeSm() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let presentStatusEle = document.getElementById(`sm_award_module_sm_present_status_${optionIdSplit[10]}_4_0`);
    if (ele) {
      if (ele.value !== '' && presentStatusEle.value !== '7' && presentStatusEle.value !== '8') {
        document.getElementById(`sm_award_module_sm_actual_technical_bid_opening_upload_${optionIdSplit[10]}_4_7`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`sm_award_module_sm_actual_technical_bid_opening_upload_${optionIdSplit[10]}_4_7`).setAttribute('data-required', 'N');
      }
    }
  }

  actualFinancialChangeSm() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let presentStatusEle = document.getElementById(`sm_award_module_sm_present_status_${optionIdSplit[10]}_4_0`);
    if (ele) {
      if (ele.value !== '' && presentStatusEle.value !== '7' && presentStatusEle.value !== '8') {
        document.getElementById(`sm_award_module_sm_actual_financial_bid_opening_upload_${optionIdSplit[10]}_4_9`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`sm_award_module_sm_actual_financial_bid_opening_upload_${optionIdSplit[10]}_4_9`).setAttribute('data-required', 'N');
      }
    }
  }

  actualLoaChangeSm() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    let presentStatusEle = document.getElementById(`sm_award_module_sm_present_status_${optionIdSplit[8]}_4_0`);
    if (ele) {
      if (ele.value !== '' && presentStatusEle.value !== '7' && presentStatusEle.value !== '8') {
        document.getElementById(`sm_award_module_sm_actual_loa_upload_${optionIdSplit[8]}_5_2`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`sm_award_module_sm_actual_loa_upload_${optionIdSplit[8]}_5_2`).setAttribute('data-required', 'N');
      }
    }
  }

  actualContractChangeSm() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    if (ele) {
      if (ele.value !== '') {
        document.getElementById(`sm_award_module_sm_contract_agreement_upload_${optionIdSplit[7]}_6`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`sm_award_module_sm_contract_agreement_upload_${optionIdSplit[7]}_6`).setAttribute('data-required', 'N');
      }
    }
  }

  actualExecutionChange() {
    let selectId = arguments[2];
    let optionIdSplit = selectId.split('_');
    let ele = document.getElementById(selectId.trim());
    if (ele) {
      if (ele.value !== '') {
        document.getElementById(`sm_award_module_sm_date_of_execution_upload_${optionIdSplit[7]}_10`).setAttribute('data-required', 'Y');
      } else {
        document.getElementById(`sm_award_module_sm_date_of_execution_upload_${optionIdSplit[7]}_10`).setAttribute('data-required', 'N');
      }
    }
  }

  getGuaranteeStartDate() {
    let selectId = arguments[2];
    let optionIdsplit = selectId.split('_');
    let selectDateId = 'eligibility_criteria_dgib_start_date_' + optionIdsplit[5] + '_5';
    let selectEndId = 'eligibility_criteria_dgib_end_date_' + optionIdsplit[5] + '_6';
    $(selectEndId).value = '';
    $(selectEndId).setAttribute("min", $(selectDateId).value);
  }
}

export const callFunctions = new functions();

let publicKey = "";
publicKey += "-----BEGIN PUBLIC KEY-----\r\n";
publicKey += "MIGfMA0GCSqGSIb3DQEBAQUAA4GNADCBiQKBgQCn+gSMOvb+6oi2eWqmxlt/qoq4\r\n";
publicKey += "3S2j7yXrLhIhtS02NPE+t14ZAQxMiJd5YPKps5ZcT2JhIdTv75ZPFmnj2+E0MhL2\r\n";
publicKey += "XkCfkW6LEg4xVMr8TeV5cyVtRRjd8XkL+awA8niJKNYIJk8y/3112cT7TnrkB6Ct\r\n";
publicKey += "4LHrrn2FG2Y9nVn8hQIDAQAB\r\n";
publicKey += "-----END PUBLIC KEY-----\r\n";


class InputEventChecker {

  static instance = null;

  inputEvents = {};

  constructor() {
    // if (InputEventChecker.instance) {
    //   return InputEventChecker.instance;
    // }
    this.inputEvents = {};
    // Initialize global data store
    // InputEventChecker.instance = this;
  }

  allEvent() {
    return this.inputEvents;
  }

  getGroupEvent(gKey) {
    const groupKeys = Array.isArray(gKey) ? gKey.join() : gKey;
    return this.inputEvents[groupKeys] ?? {};
  }

  addEvent(key, gKey = '') {
    if (gKey) {
      const groupKeys = Array.isArray(gKey) ? gKey.join() : gKey;
      if (!this.inputEvents[groupKeys]) {
        this.inputEvents[groupKeys] = {};
      }
      this.inputEvents[groupKeys][key] = true;
    } else {
      this.inputEvents[key] = true;
    }
  }

  hasEvent(key, gKey = '') {
    if (gKey) {
      const groupKeys = Array.isArray(gKey) ? gKey.join() : gKey;
      return (this.inputEvents[groupKeys] && this.inputEvents[groupKeys][key]) ? true : false;
    }
    return (this.inputEvents[key]) ? true : false;
  }

  removeEvent(key, gKey = '') {
    if (gKey) {
      const groupKeys = Array.isArray(gKey) ? gKey.join() : gKey;
      if (this.inputEvents[groupKeys] && this.inputEvents[groupKeys][key]) {
        delete this.inputEvents[groupKeys][key];
      }
      return true;
    }
    if (this.inputEvents[key]) {
      this.inputEvents[key].remove();
      delete this.inputEvents[key];
    }
    return true;
  }

  removeGroupEvent(gKey) {
    const groupKeys = Array.isArray(gKey) ? gKey.join() : gKey;
    if (this.inputEvents[groupKeys]) {
      delete this.inputEvents[groupKeys];
    }
    return true;
  }

  removeAllEvent() {
    this.inputEvents = {};
  }

}

export const inputEventChecker = new InputEventChecker();

// Store the initial attribute values
const attributesToMonitor = [
  'data-prefix', 'data-click', 'data-number', 'data-amount',
  'data-onchange', 'data-files-allowed', 'data-size', 'data-size-allowed', 'data-pattern', 'data-pattern-msg', 'data-min-number',
  'data-min-number-msg', 'silver-notempty-message', 'data-min-words', 'data-decimal', 'data-formula',
  'data-blank-allowed', 'data-average-blank-allowed', 'data-concat', 'data-conditional', 'data-org-readonly',
  'data-call-event', 'data-editblock', 'data-nagative-allowed', 'data-maxval', 'data-minval', 'data-compare-val','data-onkeyup',
  'data-no-comma', 'data-min-file', 'data-min-file-msg', 'data-max-file', 'data-max-file-msg', 'data-not-monitor'
]; // List of attributes you want to monitor

const attributesMonitor = (targetNode) => {

  const originalValues = {};

  let isObserveble = false;
  // Check if data-not-monitor is set
  let notMonitorAttr = [];
  if (targetNode.hasAttribute('data-not-monitor')) {
    notMonitorAttr = targetNode.getAttribute('data-not-monitor').split(',');
  }
  // Store all initial attributes
  for (let attr of targetNode.attributes) {
    if (attributesToMonitor.includes(attr.name)) {
      originalValues[attr.name] = attr.value;
      isObserveble = true;
    }
  }
  // check is observeble
  if (!isObserveble) return;

  const config = { attributes: true };
  const callback = function (mutationsList, observer) {
    for (const mutation of mutationsList) {
      if (mutation.type === 'attributes') {
        const attributeName = mutation.attributeName;
        // Skip the attributes you want to allow changes to
        if (attributesToMonitor.includes(attributeName)) {
          if (notMonitorAttr.includes(attributeName)) {
            continue;
          }
          const originalValue = originalValues[attributeName];

          if (mutation.target.getAttribute(attributeName) !== originalValue) {
            // console.log(`Preventing change to ${attributeName}`);
            mutation.target.setAttribute(attributeName, originalValue);
          }
        }
        // console.log(mutation);
        // console.log('The ' + mutation.attributeName + ' attribute was modified.');
        // observer.disconnect(); // Stop observing if you want to halt further changes
      }
    }
  };
  const observer = new MutationObserver(callback);
  observer.observe(targetNode, config);
}

export const isNumber = (evt, field_type) => {
  evt = (evt) ? evt : window.event;
  let charCode = (evt.which) ? evt.which : evt.keyCode;
  if (field_type === 'amount' && charCode === 46) return true;
  if (charCode > 31 && (charCode < 48 || charCode > 57)) {
    return false;
  }
  return true;
}

export const multijsoninit = (selector = '') => {
  selector = (selector) ? selector : ['.form-control'];
  let obj = document.querySelectorAll(selector);
  for (let i = 0; i < obj.length; i++) {
    const objid = obj[i].getAttribute('id');
    if (inputEventChecker.hasEvent(objid, selector)) { continue; }
    attributesMonitor(obj[i]);
    if (obj[i].hasAttribute('data-number') || obj[i].hasAttribute('data-amount')) {
      initkeyup(obj, i, selector);
    }
  }
  RunFormulas(selector);
}

let childFormulaFieldPrefix = [];

const getChildFormulaFields = (formulaobjs) => {
  let childFields = [];
  for (let fo = 0; fo < formulaobjs.length; fo++) {
    let prefix = formulaobjs[fo].getAttribute('data-prefix');
    let cFields = (!childFormulaFieldPrefix.includes(prefix)) ? document.querySelectorAll('.data-formula[data-formula*="' + prefix + '."]') : null;
    if (cFields && cFields.length > 0) {
      childFormulaFieldPrefix.push(prefix);
      const cForms = [];
      for (let cf of cFields) {
        const fID = cf.closest('form').getAttribute('id');
        if (!cForms.includes(fID)) {
          cForms.push(fID);
        }
      }
      for (let frmID of cForms) {
        let fields = document.querySelectorAll(`#${frmID} .data-formula`);
        if (fields && fields.length > 0) {
          fields = [...fields, ...getChildFormulaFields(fields)];
          childFields = [...childFields, ...fields];
        }
      }
    }
  }
  return childFields;
};

export const RunFormulas = (selector = '') => {
  selector = (selector) ? selector : ['.form-control'];
  let flds = '';
  let formulaobjs = document.querySelectorAll('.data-formula');
  // Check if there are any formulas on the page
  if (JSON.stringify(selector) != JSON.stringify(['.form-control'])) {
    formulaobjs = document.querySelectorAll(selector + '.data-formula');
    if (formulaobjs.length > 0) {
      const childFields = getChildFormulaFields(formulaobjs);
      formulaobjs = [...formulaobjs, ...childFields];
      childFormulaFieldPrefix = [];
    }
  }

  for (let fo = 0; fo < formulaobjs.length; fo++) {
    let formula = formulaobjs[fo].getAttribute('data-formula');
    let prefix = formulaobjs[fo].getAttribute('data-prefix');
    let blankAllowed = 'N';
    if (formulaobjs[fo].hasAttribute('data-blank-allowed')) {
      blankAllowed = formulaobjs[fo].getAttribute('data-blank-allowed');
    }
    let formulaBlankAllowed = formulaobjs[fo].hasAttribute('data-formula-ba') ? formulaobjs[fo].getAttribute('data-formula-ba') : 'Y';
    let averageBlankAllowed = 'N';
    if (formulaobjs[fo].hasAttribute('data-average-blank-allowed')) {
      averageBlankAllowed = formulaobjs[fo].getAttribute('data-average-blank-allowed');
    }
    let fmatch = formula.split('.');
    let counting = false;
    let isNumberAmount = (formulaobjs[fo].hasAttribute('data-number') || formulaobjs[fo].hasAttribute('data-amount'));
    let decimal = formulaobjs[fo].hasAttribute('data-amount') ? 2 : 0;
    if (formulaobjs[fo].hasAttribute('data-decimal')) decimal = formulaobjs[fo].getAttribute('data-decimal');

    if (prefix + '_' + formula === formulaobjs[fo].id) {
      let concatStr = formulaobjs[fo].hasAttribute('data-concat') ? formulaobjs[fo].getAttribute('data-concat') : '';
      let values = (isNumberAmount && blankAllowed == 'N') ? 0 : '';
      // let values = (isNumberAmount) ? 0 : '';
      let totalId = formula.replace(/_averageRound$|_average$|_total$|_concat$/, '');
      let fFieldArray = document.querySelectorAll('.' + totalId + "[data-prefix='" + prefix + "']");
      // let fFieldArray = document.querySelectorAll('.' + totalId);
      let dCount = 0;
      for (let tfo = 0; tfo < fFieldArray.length; tfo++) {
        dCount++;
        let val = fFieldArray[tfo].value;
        let isEleBlankAllowed = fFieldArray[tfo].hasAttribute('data-blank-allowed') ? fFieldArray[tfo].getAttribute('data-blank-allowed') : 'N';
        /* Added by PRTK */
        let isEleFormulaBa = fFieldArray[tfo].hasAttribute('data-formula-ba') ? fFieldArray[tfo].getAttribute('data-formula-ba') : 'Y';
        if(isEleFormulaBa == 'N' || formulaBlankAllowed == 'N'){
          isEleBlankAllowed = 'N';
        }
        /* END */
        let isEleNumAmount = (fFieldArray[tfo].hasAttribute('data-number') || fFieldArray[tfo].hasAttribute('data-amount'));
        // if (blankAllowed == 'N' && val === '') {
        if (averageBlankAllowed == 'N' && val === '') {
          dCount--;
        }
        if (isEleNumAmount && (isEleBlankAllowed == 'N' || val !== '')) {
          // if (isEleNumAmount) {
          if (val === '-') val = 0;
          val = strToNumber(val) * 1;
        }
        if (
          (concatStr && formula.match(/_concat$/) && fFieldArray.length != (tfo + 1)) &&
          blankAllowed == 'N' && val !== ''
        ) {
          val += concatStr;
        }

        if ((isNumberAmount && blankAllowed !== 'N') && values === '') {
          values = val;
        } else if (isEleNumAmount && values !== '' && val !== '') {
          values += val;
        } else if (!isEleNumAmount) {
          values += val;
        }
        // values += val;
      }
      if (formula.match(/_concat$/)) {
        values = values.replace((new RegExp(concatStr + '$')), '');
      }
      if (formula.match(/_average$|_averageRound$/)) {
        if (blankAllowed == 'N' || values !== '') {
          values = (values) ? values / dCount : 0;
          if (formula.match(/_averageRound$/)) {
            values = Math.round(values);
          }
        }
      }

      if (isNumberAmount && (blankAllowed == 'N' || values !== '')) {
        // if (isNumberAmount) {
        formulaobjs[fo].value = toFixed(values, decimal);
      } else {
        formulaobjs[fo].value = values;
      }
      counting = true;
    }
    else if (fmatch.length > 1) {
      // replace rowidxval with actual value
      const fIdWithoutPrefix = formulaobjs[fo].id.replace(prefix + '_', '');
      const fIdArray = fIdWithoutPrefix.split('_').reverse();
      const patternFormula = fmatch[0].split('_').reverse();
      for (let riv = 0; riv < patternFormula.length; riv++) {
        if (patternFormula[riv].match(/rowidxval/) && fIdArray[riv]) {
          patternFormula[riv] = fIdArray[riv];
        }
      }
      const patternFormulaId = patternFormula.reverse().join('_');
      if (prefix + '_' + patternFormulaId === formulaobjs[fo].id) {
        counting = true;
        const patternField = fmatch[1].split('_').reverse();
        let fivCount = 0;
        for (let fiv = 0; fiv < patternField.length; fiv++) {
          if (patternField[fiv].match(/rowidxval/) && fIdArray[fivCount]) {
            patternField[fiv] = fIdArray[fivCount];
          }
          fivCount++;
        }
        const patternFieldId = patternField.reverse().join('_');
        const patternFieldIdArray = patternFieldId.split('_');
        const formulaIdArray = fIdArray.reverse();
        let type = '';
        let fieldClass = [];
        for (let ll = 0; ll < formulaIdArray.length; ll++) {
          if (formulaIdArray[ll] !== patternFieldIdArray[ll]) {
            type = formulaIdArray[ll];
            break;
          } else {
            fieldClass.push(formulaIdArray[ll]);
          }
        }

        const fieldID = patternFieldId.split('*')[0];
        fieldClass = fieldClass.join('_');
        let concatStr = formulaobjs[fo].hasAttribute('data-concat') ? formulaobjs[fo].getAttribute('data-concat') : '';
        let values = (isNumberAmount && blankAllowed == 'N') ? 0 : '';
        // let values = isNumberAmount ? 0 : '';
        let fFieldArray = document.querySelectorAll(`.${fieldClass}[id^="${prefix}_${fieldID}"]`);
        let dCount = 0;
        for (let tfo = 0; tfo < fFieldArray.length; tfo++) {
          dCount++;
          let val = fFieldArray[tfo].value;
          let isEleBlankAllowed = fFieldArray[tfo].hasAttribute('data-blank-allowed') ? fFieldArray[tfo].getAttribute('data-blank-allowed') : 'N';
          /* Added by PRTK */
          let isEleFormulaBa = fFieldArray[tfo].hasAttribute('data-formula-ba') ? fFieldArray[tfo].getAttribute('data-formula-ba') : 'Y';
          if(isEleFormulaBa == 'N' || formulaBlankAllowed == 'N'){
            isEleBlankAllowed = 'N';
          }
          /* END */
          let isEleNumAmount = (fFieldArray[tfo].hasAttribute('data-number') || fFieldArray[tfo].hasAttribute('data-amount'));
          // if (blankAllowed == 'N' && val === '') {
          if (averageBlankAllowed == 'N' && val === '') {
            dCount--;
          }
          if (isEleNumAmount && (isEleBlankAllowed == 'N' || val !== '')) {
            // if (isEleNumAmount) {
            if (val === '-') val = 0;
            val = strToNumber(val) * 1;
          }
          if (
            (concatStr && type === 'concat' && fFieldArray.length != (tfo + 1)) &&
            blankAllowed == 'N' && val !== ''
          ) {
            val += concatStr;
          }
          if ((isNumberAmount && blankAllowed !== 'N') && values === '') {
            values = val;
          } else if (isEleNumAmount && values !== '' && val !== '') {
            values += val;
          } else if (!isEleNumAmount) {
            values += val;
          }
          // values += val;
        }
        if (type === 'average' || type === 'averageRound') {
          if (blankAllowed == 'N' || values !== '') {
            values = (values) ? values / dCount : 0;
            if (type === 'averageRound') {
              values = Math.round(values);
            }
          }
        }

        if (isNumberAmount && (blankAllowed == 'N' || values !== '')) {
          // if (isNumberAmount) {
          formulaobjs[fo].value = toFixed(values, decimal);
        } else {
          formulaobjs[fo].value = values;
        }
      }
    }
    if (!counting) {
      let newformula = formulaobjs[fo].getAttribute('data-formula');
      let pat = /\{(.*?)\}/g;
      do {
        flds = pat.exec(formula);
        if (flds) {
          let dataInput;
          let objinval;
          if (flds[1].match(/\./)) {
            let tmpfld = flds[1];
            tmpfld = tmpfld.replace('.', '_');
            dataInput = $(tmpfld);
            if (dataInput && dataInput.value !== '') {
              objinval = dataInput.value;
            }
            const fNameWithoutPrefix = formulaobjs[fo].id.replace(prefix + '_', '');
            const optionIds = fNameWithoutPrefix.split('_').reverse();
            const patternField = tmpfld.split('_').reverse();
            for (let riv = 0; riv < patternField.length; riv++) {
              if (patternField[riv].match(/rowidxval/) && optionIds[riv]) {
                patternField[riv] = optionIds[riv];
              }
            }
            const patternFieldId = patternField.reverse().join('_');
            dataInput = $(patternFieldId);
            if (dataInput && dataInput.value !== '') {
              objinval = dataInput.value;
            }
          }
          else {
            dataInput = $(prefix + '_' + flds[1]);
            if (dataInput && dataInput.value !== '') {
              objinval = dataInput.value;
            }
            let optionIds = formulaobjs[fo].id.split('_').reverse();
            let patternField = flds[1].split('_').reverse();
            for (let riv = 0; riv < patternField.length; riv++) {
              if (patternField[riv].match(/rowidxval/)) {
                patternField[riv] = optionIds[riv];
              }
            }
            let patternFieldId = patternField.reverse().join('_');
            dataInput = $(prefix + '_' + patternFieldId);
            if (dataInput && dataInput.value !== '') {
              objinval = dataInput.value;
            }
          }
          if (dataInput) {
            let isElmBlankAllowed = dataInput.hasAttribute('data-blank-allowed') ? dataInput.getAttribute('data-blank-allowed') : 'N';
            let isElmNumAmount = (dataInput.hasAttribute('data-number') || dataInput.hasAttribute('data-amount'));
            if (objinval === '-' && isNumberAmount) objinval = 0;
            if (isNumberAmount || isElmNumAmount) {
              if (blankAllowed == 'N' && (objinval === '' || objinval === null || objinval === undefined)) {
                objinval = 0;
              }
            }
            if (isNumberAmount || isElmNumAmount) {
              objinval = strToNumber(objinval);
              objinval = objinval * 1;
            }
            newformula = newformula.replace('{' + flds[1] + '}', objinval);
          } else {
            // Add by zk - September 4 2024 
            objinval = '';
            if (isNumberAmount) {
              objinval = 0;
            }
            newformula = newformula.replace('{' + flds[1] + '}', objinval);
          }
        }
      } while (flds);
      //console.log('Orginal Formula', formula, formulaobjs[fo].value);
      //console.log('Replace Formula', newformula);
      try {
        formulaobjs[fo].value = eval(newformula);
      } catch (e) {
        // console.log(e, newformula);
        formulaobjs[fo].value = (isNumberAmount && blankAllowed == 'N') ? 0 : '';
        // formulaobjs[fo].value = (isNumberAmount) ? 0 : '';
      }
      if (isNumberAmount && (blankAllowed == 'N' || formulaobjs[fo].value != '')) {
        // if (isNumberAmount) {
        if (formulaobjs[fo].value === 'NaN') formulaobjs[fo].value = 0;
        let tmpval = formulaobjs[fo].value * 1;
        formulaobjs[fo].value = toFixed(tmpval, decimal);
      }
      //console.log('Output Formula', formulaobjs[fo].value);
    }
  }
}

export const CheckConditionals = (el) => {
  const fieldName = el.name;
  const matches = [...fieldName.matchAll(/\[(.*?)\]/g)].map(match => match[1]);
  const filtered = matches.filter(item => isNaN(item) && item !== '');
  const fname = filtered[filtered.length - 1];
  // const isMatch = el.classList.contains(fname);
  // if (!isMatch) return;
  const conditionalObj = document.querySelectorAll(`.data-conditional[data-conditional*="${fname}"]`);
  if (!conditionalObj || conditionalObj.length === 0) return;
  for (let conField of conditionalObj) {
    let field = '';
    const nodeType = conField.type;
    const nodeName = conField.nodeName;
    const prefix = conField.getAttribute('data-prefix');
    const conditionals = conField.getAttribute('data-conditional');
    let newConditionals = conField.getAttribute('data-conditional');
    let blankAllowed = conField.hasAttribute('data-blank-allowed') ? conField.getAttribute('data-blank-allowed') : 'N';
    let isNumAmount = (conField.hasAttribute('data-number') || conField.hasAttribute('data-amount'));
    let isReadonly = conField.hasAttribute('data-org-readonly');

    do {
      field = /\{(.*?)\}/g.exec(newConditionals);
      if (field) {
        let fieldElm;
        let fieldVal;
        if (field[1].match(/\./)) {
          let tmpField = field[1];
          tmpField = tmpField.replace('.', '_');
          fieldElm = $(tmpField);
          if (fieldElm && fieldElm.value !== '') {
            fieldVal = fieldElm.value;
          }
          const fNameWithoutPrefix = conField.id.replace(prefix + '_', '');
          const optionIds = fNameWithoutPrefix.split('_').reverse();
          const patternField = tmpField.split('_').reverse();
          for (let riv = 0; riv < patternField.length; riv++) {
            if (patternField[riv].match(/rowidxval/) && optionIds[riv]) {
              patternField[riv] = optionIds[riv];
            }
          }
          const patternFieldId = patternField.reverse().join('_');
          fieldElm = $(patternFieldId);
          if (fieldElm && fieldElm.value !== '') {
            fieldVal = fieldElm.value;
          }
        }
        else {
          fieldElm = $(prefix + '_' + field[1]);
          if (fieldElm && fieldElm.value !== '') {
            fieldVal = fieldElm.value;
          }
          let optionIds = conField.id.split('_').reverse();
          let patternField = field[1].split('_').reverse();
          for (let riv = 0; riv < patternField.length; riv++) {
            if (patternField[riv].match(/rowidxval/)) {
              patternField[riv] = optionIds[riv];
            }
          }
          let patternFieldId = patternField.reverse().join('_');
          fieldElm = $(prefix + '_' + patternFieldId);
          if (fieldElm && fieldElm.value !== '') {
            fieldVal = fieldElm.value;
          }
        }
        if (fieldElm) {
          let isElmBlankAllowed = fieldElm.hasAttribute('data-blank-allowed') ? fieldElm.getAttribute('data-blank-allowed') : 'N';
          let isElmNumAmount = (fieldElm.hasAttribute('data-number') || fieldElm.hasAttribute('data-amount'));
          if (fieldVal === '-' && isElmNumAmount) fieldVal = 0;
          if (isElmNumAmount) {
            if (isElmBlankAllowed == 'N' && (fieldVal === '' || fieldVal === null || fieldVal === undefined)) {
              fieldVal = 0;
            }
            fieldVal = strToNumber(fieldVal);
            fieldVal = fieldVal * 1;
          }
          newConditionals = newConditionals.replace('{' + field[1] + '}', fieldVal);
        }
      }
    } while (field);
    // console.log('Orginal Conditional', conditionals);
    // console.log('Replace Conditional', newConditionals);
    try {
      const isMatch = eval(newConditionals);
      if (!conField.hasAttribute('data-old-ftype')) {
        conField.setAttribute('data-old-ftype', (nodeType && nodeName));
      }
      let isCreateEle = false;
      if (isMatch) {
        if (!isReadonly) conField.removeAttribute('readOnly');
        conField.classList.remove('conditional-hide');
        if (conField.hasAttribute('data-conditional-true')) {
          conField.value = conField.getAttribute('data-conditional-true');
        }
        if (conField.hasAttribute('data-conditional-ftype')) {
          if (nodeName === 'SELECT') {
            conField.removeAttribute('disabled', 'true');
            const conInputs = conField.parentNode.querySelectorAll('.data-conditional-input');
            if (conInputs && conInputs.length > 0) {
              for (let conInput of conInputs) {
                conInput.remove();
              };
            }
          } else if (conField.getAttribute('data-old-ftype')) {
            conField.setAttribute('type', conField.getAttribute('data-old-ftype'));
          }
        }
        if (conField.hasAttribute('data-conditional-fattr')) {
          const fattr = JSON.parse(conField.getAttribute('data-conditional-fattr'));
          Object.keys(fattr).filter((key) => {
            conField.removeAttribute(key);
          });
        }
        if (conField.hasAttribute('data-conditional-attr')) {
          const attr = JSON.parse(conField.getAttribute('data-conditional-attr'));
          Object.keys(attr).filter((key) => {
            conField.setAttribute(key, attr[key]);
          });
        }
      } else {
        if (!isReadonly) conField.setAttribute('readOnly', 'true');
        conField.classList.add('conditional-hide');
        conField.value = '';
        if (conField.hasAttribute('data-conditional-false')) {
          conField.value = conField.getAttribute('data-conditional-false');
        }
        if (conField.hasAttribute('data-conditional-ftype')) {
          if (nodeName === 'SELECT') {
            conField.setAttribute('disabled', 'true');
            const conInputs = conField.parentNode.querySelectorAll('.data-conditional-input');
            if (conInputs && conInputs.length > 0) {
              for (let conInput of conInputs) {
                conInput.remove();
              };
            }
            isCreateEle = true;
          } else {
            conField.setAttribute('type', conField.hasAttribute('data-conditional-ftype'));
          }
        }
        if (conField.hasAttribute('data-conditional-attr')) {
          const attr = JSON.parse(conField.getAttribute('data-conditional-attr'));
          Object.keys(attr).filter((key) => {
            conField.removeAttribute(key);
          });
        }
        if (!isCreateEle && conField.hasAttribute('data-conditional-fattr')) {
          const fattr = JSON.parse(conField.getAttribute('data-conditional-fattr'));
          Object.keys(fattr).filter((key) => {
            conField.setAttribute(key, fattr[key]);
          });
        }
      }
      // if (isNumAmount && blankAllowed == 'N') {
      //     conField.value = 0;
      // }
      // if (isNumAmount && (conField.value !== '' || conField.value !== null)) {
      //     conField.value = strToNumber(conField.value);
      //     if (conField.hasAttribute('data-amount')) conField.value = toFixed(conField.value, 2);
      // }
      if (isCreateEle) {
        const input = document.createElement('input');
        input.setAttribute('readOnly', 'true');
        input.setAttribute('type', conField.getAttribute('data-conditional-ftype'));
        input.setAttribute('name', conField.getAttribute('name'));
        input.setAttribute('id', conField.getAttribute('id'));
        input.setAttribute('class', 'form-control data-conditional-input');
        input.setAttribute('data-prefix', conField.getAttribute('data-prefix'));
        if (conField.hasAttribute('data-conditional-fattr')) {
          const fattr = JSON.parse(conField.getAttribute('data-conditional-fattr'));
          Object.keys(fattr).filter((key) => {
            conField.setAttribute(key, fattr[key]);
          });
        }
        input.value = conField.value;
        conField.parentNode.insertBefore(input, conField.nextSibling);
      }
    } catch (e) {
      // console.log(e, conditionals);
    }
  }
}

export const multijsonsop32init = () => {
  let id2 = 'pqsop_details_sop_3_2_discom_details_status_text';
  let sop_3_2_total = strToNumber($('pqsop_details_overdues_as_on_date_total').value);

  if (sop_3_2_total * 1 > 0) {
    $(id2).value = 'Non-compliant';
    $(id2).classList.add('red');
    $(id2).classList.remove('green');
  }
  else {
    $(id2).value = 'Compliant';
    $(id2).classList.add('green');
    $(id2).classList.remove('red');
  }
}

export const climInvoiceDateinit = (selector) => {

  let optionIdsplit = selector.split(',');
  let invoiceDate = optionIdsplit[1].trim();
  let invoiceDateIndex = invoiceDate.split('_');
  let paymentReferenceDate = optionIdsplit[0] + '_payment_reference_date_10_' + invoiceDateIndex[6] + '_7_0_8';
  $(paymentReferenceDate).setAttribute("min", $(invoiceDate).value);

  const table = document.querySelector('#invoice_date_' + invoiceDateIndex[6] + '');
  if (table) {
    const rows = table.querySelectorAll('tr');
    rows.forEach((row) => {
      const cells = row.querySelectorAll('td .payment_reference_date');
      const values = Array.from(cells).map((cell) => cell.id);
      Object.values(values).forEach(element => {
        $(element).value = '';
        $(element).setAttribute("min", $(invoiceDate).value);
      });
    });
  }
}

export const climInvoiceDateaddMoreInit = (prifix, pIndex) => {

  const table = document.querySelector('#invoice_date_' + pIndex + '');
  if (table) {
    const rows = table.querySelectorAll('tr');
    let invoiceDate = prifix + '_invoice_date_' + pIndex + '_1';

    rows.forEach((row) => {
      const cells = row.querySelectorAll('td .payment_reference_date');
      const values = Array.from(cells).map((cell) => cell.id);
      Object.values(values).forEach(element => {
        $(element).setAttribute("min", $(invoiceDate).value);
      });
    });
  }
}

export const climInvoiceDateaEditInit = (prifix) => {

  const mianTable = document.querySelector('#claim_tabel_id');

  if (mianTable) {
    const mainRows = mianTable.querySelectorAll('tbody tr');
    const pIndex = 0;
    mainRows.forEach(function (rowIndex) {

      let pIndex = rowIndex.getAttribute('data-id');
      if (pIndex) {
        const table = document.querySelector('#invoice_date_' + pIndex + '');
        if (table != null) {
          const rows = table.querySelectorAll('tr');
          let invoiceDate = prifix + '_invoice_date_' + pIndex + '_1';

          rows.forEach((row) => {
            const cells = row.querySelectorAll('td .payment_reference_date');
            const values = Array.from(cells).map((cell) => cell.id);

            Object.values(values).forEach(element => {
              $(element).setAttribute("min", $(invoiceDate).value);
            });
          });
        }
      }
    });
  }
}

export const pqforcurrentyearcal = () => {

  let id4 = "pqsop_details_pq4_sub_previous_deficit_total";
  let id5 = "pqsop_details_pq4_sub_previous_status";

  if (($(id4).value)) {
    if ((strToNumber($(id4).value) * 1) > 0) {
      $(id5).value = 'Non-compliant';
      $(id5).classList.add('red');
      $(id5).classList.remove('green');
    }
    else {
      $(id5).value = 'Compliant';
      $(id5).classList.add('green');
      $(id5).classList.remove('red');
    }
  }
  else {
    $(id5).value = '';
  }

  pq4allstatus();
}

export const pqforadvancesubsidycal = () => {

  let id4 = "pqsop_details_pq4_sub_current_deficit_total";
  let id5 = "pqsop_details_pq4_sub_current_status";
  if ($(id4).value) {
    if ((strToNumber($(id4).value) * 1) > 0) {
      $(id5).value = 'Non-compliant';
      $(id5).classList.add('red');
      $(id5).classList.remove('green');
    }
    else {
      $(id5).value = 'Compliant';
      $(id5).classList.add('green');
      $(id5).classList.remove('red');
    }
  }
  else {
    $(id5).value = '';
  }

  pq4allstatus();
}

function removeAllListeners(element) {
  const clone = element.cloneNode(true);
  element.parentNode.replaceChild(clone, element);
  return clone;
}

export const initkeyup = (obj, i, selector = '') => {
  let isShift = false;
  let isCtrl = false;
  let debounceTimeout = null;
  const objid = obj[i].getAttribute('id');
  let inputElement = $(objid);
  const nodeType = inputElement.type;
  const nodeName = inputElement.nodeName;
  const isReadonly = inputElement.hasAttribute('readonly');
  const isDisabled = inputElement.hasAttribute('disabled');
  const callEvent = inputElement.hasAttribute('data-call-event') ? inputElement.getAttribute('data-call-event') : false;
  //if (!isReadonly && !isDisabled) {
  if ((nodeName === 'INPUT' && nodeType !== 'file') || nodeName === 'TEXTAREA') {
    if (objid.match(/\s/) || inputEventChecker.hasEvent(objid, selector)) {
      return;
    }

    inputEventChecker.addEvent(objid, selector);
    // inputElement = removeAllListeners(inputElement);

    let blankAllowed = 'N';
    if (inputElement.hasAttribute('data-blank-allowed')) {
      blankAllowed = inputElement.getAttribute('data-blank-allowed');
    }
    // how to fix this issue [Violation] 'setTimeout' handler took <N>ms

    inputElement.addEventListener('keydown', function (evt) {

      if (this.hasAttribute('data-editblock')) {
        this.value = '';
        evt.preventDefault();
        return false;
      }

      // console.log(evt.keyCode);
      if (evt.keyCode === 16) isShift = true;
      if (evt.keyCode === 17 || evt.keyCode === 224) isCtrl = true;
      if (this.hasAttribute('data-number') || this.hasAttribute('data-amount')) {
        let nagativeAllowed = 'Y';
        if (this.hasAttribute('data-nagative-allowed')) nagativeAllowed = this.getAttribute('data-nagative-allowed');
        if (nagativeAllowed == 'N' && (evt.keyCode === 173 || evt.keyCode === 189)) {
          evt.preventDefault();
          return;
        }
        let generalcodes = [46, 8, 37, 39, 9];
        let allowedkeycodes = [];
        if (this.hasAttribute('data-number'))
          allowedkeycodes = [46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8, 37, 38, 39, 40, 9, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 173, 189];
        if (this.hasAttribute('data-amount'))
          allowedkeycodes = [46, 48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8, 37, 38, 39, 40, 189, 190, 173, 9, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110];
        if (isShift) {
          if (evt.keyCode === 9) return;
          evt.preventDefault();
          return;
        }
        if (isCtrl) {
          if (this.hasAttribute('data-number'))
            allowedkeycodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8, 37, 38, 39, 40, 9, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 173, 189, 67, 86];
          if (this.hasAttribute('data-amount'))
            allowedkeycodes = [48, 49, 50, 51, 52, 53, 54, 55, 56, 57, 8, 37, 38, 39, 40, 189, 190, 173, 9, 96, 97, 98, 99, 100, 101, 102, 103, 104, 105, 110, 67, 86];
        }
        if (!allowedkeycodes.includes(evt.keyCode)) {
          evt.preventDefault();
          return;
        }
        let tabobj = null;
        if (evt.keyCode === 37 && this.selectionStart === 0) {
          let tabindex = this.getAttribute('tabindex');
          tabindex--;
          tabobj = document.querySelector('[tabindex = "' + tabindex + '"]');
        }
        if (evt.keyCode === 39 && this.selectionStart === this.length) {
          let tabindex = this.getAttribute('tabindex');
          tabindex++;
          tabobj = document.querySelector('[tabindex = "' + tabindex + '"]');
        }
        if (evt.keyCode === 40 || evt.keyCode === 38) {
          let tabindex = this.getAttribute('tabindex');
          if (tabindex > 0) {
            evt.keyCode === 40 ? tabindex++ : tabindex--;
            tabobj = document.querySelector('[tabindex = "' + tabindex + '"]');
          }
        }
        if (tabobj && tabobj?.focus) {
          tabobj?.focus();
        }
        if (tabobj && tabobj?.select) {
          tabobj?.select();
        }
        let allowed_decimal = 2;
        if (this.hasAttribute('data-decimal')) allowed_decimal = this.getAttribute('data-decimal');
        let dotpos = this.value.indexOf('.');
        allowed_decimal = allowed_decimal * 1;
        if (dotpos > -1 && !generalcodes.includes(evt.keyCode) && (this.selectionStart > dotpos && this.value.length > dotpos + allowed_decimal)) {
          evt.preventDefault();
          return;
        }
        if (evt.keyCode === 173 || evt.keyCode === 189) {
          if (this.value.match(/\-/))
            this.value = this.value.replace('-', '');
          else
            this.value = '-' + this.value;

          evt.preventDefault();
          return;
        }
        if (evt.keyCode === 190) {
          if (this.value.match(/\./) || this.selectionStart < this.value.length - allowed_decimal) {
            evt.preventDefault();
            return;
          }
        }
      }
    });

    inputElement.addEventListener('keyup', function (evt) {

      if (this.hasAttribute('data-editblock')) {
        this.value = '';
        evt.preventDefault();
        return false;
      }

      let prevCode = evt.keyCode;
      // if (evt.keyCode === 9) return;
      if (prevCode && evt.keyCode === 16) {
        isShift = false;
        return;
      }
      if (prevCode && (evt.keyCode === 17 || evt.keyCode === 224)) {
        if (this.hasAttribute('data-number') || this.hasAttribute('data-amount'))
          this.value = this.value.replace(/[^\-.0-9]+/g, '');
        isCtrl = false;
        return;
      }

      if (this.hasAttribute('data-sop34')) {
        let id1 = 'pqsop_details_closing_regulatory_assets';
        let id2 = 'pqsop_details_closing_uncovered_assets';
        let id3 = 'pqsop_details_sop_3_4_outst_regulatory_status_1';
        let sumsop34 = strToNumber($(id1).value) * 1 + strToNumber($(id2).value) * 1;

        if (sumsop34 > 0) {
          $(id3).value = 'Non-compliant';
          $(id3).classList.add('red');
          $(id3).classList.remove('green');

          let id4 = 'pqsop_details_sop_3_4_existing_regulatory_status_1';
          $(id4).value = '';

          let id5 = 'pqsop_details_sop_3_4_whether_status_text';
          $(id5).value = '';

          // $('pqsop_details_sop_3_4_existing_regulatory_status_1').value = '';
          // $('pqsop_details_sop_3_4_whether_status_text').value = '';
          $("pqsop_details_sop_3_4_existing_regulatory_details_select_yes").removeAttribute("disabled");
          $("pqsop_details_sop_3_4_existing_regulatory_details_select_no").removeAttribute("disabled");
          $("pqsop_details_sop_3_4_whether_details_select_yes").removeAttribute("disabled");
          $("pqsop_details_sop_3_4_whether_details_select_no").removeAttribute("disabled");

          let id6 = 'pqsop_details_sop_3_4_existing_regulatory_details_select_yes';
          let id7 = 'pqsop_details_sop_3_4_existing_regulatory_details_select_no';
          let id8 = 'pqsop_details_sop_3_4_whether_details_select_yes';
          let id9 = 'pqsop_details_sop_3_4_whether_details_select_no';

          $(id6).value = '';
          $(id7).value = '';
          $(id8).value = '';
          $(id9).value = '';
        }
        else {
          $(id3).value = 'Compliant';
          $(id3).classList.add('green');
          $(id3).classList.remove('red');

          let id4 = 'pqsop_details_sop_3_4_existing_regulatory_status_1';

          $(id4).value = 'Compliant';
          $(id4).classList.add('green');
          $(id4).classList.remove('red');

          let id5 = 'pqsop_details_sop_3_4_whether_status_text';

          $(id5).value = 'Compliant';
          $(id5).classList.add('green');
          $(id5).classList.remove('red');

          $("pqsop_details_sop_3_4_existing_regulatory_details_select_yes").setAttribute("disabled", true);
          $("pqsop_details_sop_3_4_existing_regulatory_details_select_no").setAttribute("disabled", true);
          $("pqsop_details_sop_3_4_whether_details_select_yes").setAttribute("disabled", true);
          $("pqsop_details_sop_3_4_whether_details_select_no").setAttribute("disabled", true);
        }

        let id4 = 'pqsop_details_regulatory_opening_balance';
        let id5 = 'pqsop_details_regulatory_tariff_order';
        let id6 = 'pqsop_details_past_regulatory_tariff_order';
        if (strToNumber($(id4).value) * 1 <= 0) {
          $(id6).setAttribute('readOnly', 'true');
          $(id6).value = 0;
        } else {
          $(id6).removeAttribute('readOnly');
        }

        let id7 = 'pqsop_details_uncovered_opening_balance';
        let id8 = 'pqsop_details_uncovered_tariff_order';
        let id9 = 'pqsop_details_past_uncovered_tariff_order';
        if (strToNumber($(id7).value) * 1 <= 0) {
          $(id9).setAttribute('readOnly', 'true');
          $(id9).value = 0;
        } else {
          $(id9).removeAttribute('readOnly');
        }

        let decimal1 = $(id1).hasAttribute('data-amount') ? 2 : 0;
        let decimal2 = $(id2).hasAttribute('data-amount') ? 2 : 0;
        if ($(id1).hasAttribute('data-decimal')) decimal1 = $(id1).getAttribute('data-decimal');
        if ($(id2).hasAttribute('data-decimal')) decimal2 = $(id2).getAttribute('data-decimal');
        $(id1).value = toFixed(strToNumber($(id4).value) * 1 + strToNumber($(id5).value) * 1 - strToNumber($(id6).value) * 1, decimal1);
        $(id2).value = toFixed(strToNumber($(id7).value) * 1 + strToNumber($(id8).value) * 1 - strToNumber($(id9).value) * 1, decimal2);
      }

      if (this.hasAttribute('data-pq4')) {

        let id1 = 'pqsop_details_subsidy_target';
        let id2 = 'pqsop_details_subsidy_actual';
        let id3 = 'pqsop_details_subsidy_status';

        if ($(id1).value && $(id2).value) {
          if (strToNumber($(id2).value) * 1 >= strToNumber($(id1).value) * 1) {
            $(id3).value = 'Compliant';
            $(id3).classList.add('green');
            $(id3).classList.remove('red');
          }
          else {
            $(id3).value = 'Non-compliant';
            $(id3).classList.add('red');
            $(id3).classList.remove('green');
          }
        } else {
          $(id3).value = '';
        }

        pq4allstatus();
      }

      if (this.hasAttribute('readonly'))
        return;

      if (this.hasAttribute('data-number') || this.hasAttribute('data-amount')) {
        this.value = this.value.replace(/[^\-.0-9]+/g, '');

        // Check max and min value
        if (this.hasAttribute('data-maxval')) {
          let maxval = Number(this.getAttribute('data-maxval'));
          if (Number(this.value) > maxval) {
            this.value = maxval;
          }
        }
        if (this.hasAttribute('data-minval')) {
          let minval = Number(this.getAttribute('data-minval'));
          if (Number(this.value) < minval) {
            this.value = minval;
          }
        }
      }

      // Call Formula run
      // Clear the previous timeout
      if (debounceTimeout) {
        clearTimeout(debounceTimeout);
      }
      debounceTimeout = setTimeout(() => {
        RunFormulas(selector);
        if (this.hasAttribute('data-sop32')) {
          multijsonsop32init();
        }

        if (this.hasAttribute('data-pq5')) {
          let id2 = 'pqsop_details_pq5_electricity_status_text_2';
          if (strToNumber($('pqsop_details_balance').value) * 1 > 0) {
            $(id2).value = 'Non-compliant';
            $(id2).classList.add('red');
            $(id2).classList.remove('green');
          }
          else {
            $(id2).value = 'Compliant';
            $(id2).classList.add('green');
            $(id2).classList.remove('red');
          }

          let id3 = 'pqsop_details_pq5_electricity_due_details_text_1';
          let id4 = 'pqsop_details_pq5_electricity_due_details_text_2';
          $(id3).value = $('pqsop_details_total_billed').value;
          $(id4).value = $('pqsop_details_total_paid').value;
        }

        if (this.hasAttribute('data-pq4')) {
          pqforcurrentyearcal();
          pqforadvancesubsidycal();
        }

        if (this.hasAttribute('data-sop34')) {
          let id1 = 'pqsop_details_closing_regulatory_assets';
          let id2 = 'pqsop_details_closing_uncovered_assets';
          let id3 = 'pqsop_details_sop_3_4_outst_regulatory_status_1';
          let sumsop34 = (strToNumber($(id1).value) * 1) + (strToNumber($(id2).value) * 1);

          // console.log(sumsop34);

          if (sumsop34 > 0) {
            $(id3).value = 'Non-compliant';
            $(id3).classList.add('red');
            $(id3).classList.remove('green');

            // $('pqsop_details_sop_3_4_existing_regulatory_status_1').value = '';
            // $('pqsop_details_sop_3_4_whether_status_text').value = '';
            $("pqsop_details_sop_3_4_existing_regulatory_details_select_yes").removeAttribute("disabled");
            $("pqsop_details_sop_3_4_existing_regulatory_details_select_no").removeAttribute("disabled");
            $("pqsop_details_sop_3_4_whether_details_select_yes").removeAttribute("disabled");
            $("pqsop_details_sop_3_4_whether_details_select_no").removeAttribute("disabled");

            let id4 = 'pqsop_details_sop_3_4_existing_regulatory_status_1';
            let id5 = 'pqsop_details_sop_3_4_whether_status_text';

            $(id4).value = '';
            $(id5).value = '';

            let id6 = 'pqsop_details_sop_3_4_existing_regulatory_details_select_yes';
            let id7 = 'pqsop_details_sop_3_4_existing_regulatory_details_select_no';
            let id8 = 'pqsop_details_sop_3_4_whether_details_select_yes';
            let id9 = 'pqsop_details_sop_3_4_whether_details_select_no';

            $(id6).checked = false;
            $(id7).checked = false;
            $(id8).checked = false;
            $(id9).checked = false;
          }
          else {
            $(id3).value = 'Compliant';
            $(id3).classList.add('green');
            $(id3).classList.remove('red');

            let id4 = 'pqsop_details_sop_3_4_existing_regulatory_status_1';

            $(id4).value = 'Compliant';
            $(id4).classList.add('green');
            $(id4).classList.remove('red');

            let id5 = 'pqsop_details_sop_3_4_whether_status_text';

            $(id5).value = 'Compliant';
            $(id5).classList.add('green');
            $(id5).classList.remove('red');

            $("pqsop_details_sop_3_4_existing_regulatory_details_select_yes").setAttribute("disabled", true);
            $("pqsop_details_sop_3_4_existing_regulatory_details_select_no").setAttribute("disabled", true);
            $("pqsop_details_sop_3_4_whether_details_select_yes").setAttribute("disabled", true);
            $("pqsop_details_sop_3_4_whether_details_select_no").setAttribute("disabled", true);
          }

          let id4 = 'pqsop_details_regulatory_opening_balance';
          let id5 = 'pqsop_details_regulatory_tariff_order';
          let id6 = 'pqsop_details_past_regulatory_tariff_order';
          if (strToNumber($(id4).value) * 1 <= 0) {
            $(id6).setAttribute('readOnly', 'true');
            $(id6).value = 0;
          } else {
            $(id6).removeAttribute('readOnly');
          }

          let id7 = 'pqsop_details_uncovered_opening_balance';
          let id8 = 'pqsop_details_uncovered_tariff_order';
          let id9 = 'pqsop_details_past_uncovered_tariff_order';
          if (strToNumber($(id7).value) * 1 <= 0) {
            $(id9).setAttribute('readOnly', 'true');
            $(id9).value = 0;
          } else {
            $(id9).removeAttribute('readOnly');
          }

          let decimal1 = $(id1).hasAttribute('data-amount') ? 2 : 0;
          let decimal2 = $(id2).hasAttribute('data-amount') ? 2 : 0;
          if ($(id1).hasAttribute('data-decimal')) decimal1 = $(id1).getAttribute('data-decimal');
          if ($(id2).hasAttribute('data-decimal')) decimal2 = $(id2).getAttribute('data-decimal');
          $(id1).value = toFixed(strToNumber($(id4).value) * 1 + strToNumber($(id5).value) * 1 - strToNumber($(id6).value) * 1, decimal1);
          $(id2).value = toFixed(strToNumber($(id7).value) * 1 + strToNumber($(id8).value) * 1 - strToNumber($(id9).value) * 1, decimal2);
        }

        clearTimeout(debounceTimeout);
      }, 300);

      if (this.hasAttribute('data-pq3')) {
        let id1 = 'pqsop_details_pq3_order_current_textarea';
        let id2 = 'pqsop_details_pq3_projected_revenue';
        let id3 = 'pqsop_details_pq3_order_status';
        let pq3value = strToNumber($(id1).value) * 1 - strToNumber($(id2).value) * 1;

        if ((pq3value) > 0) {
          $(id3).value = 'Non-compliant';
          $(id3).classList.add('red');
          $(id3).classList.remove('green');
        }
        else {
          $(id3).value = 'Compliant';
          $(id3).classList.add('green');
          $(id3).classList.remove('red');
        }

      }

      if (this.hasAttribute('data-sop32')) {
        let id2 = 'pqsop_details_sop_3_2_discom_details_status_text';
        if (strToNumber($('pqsop_details_overdues_as_on_date_total').value) * 1 > 0) {
          $(id2).value = 'Non-compliant';
          $(id2).classList.add('red');
          $(id2).classList.remove('green');
        }
        else {
          $(id2).value = 'Compliant';
          $(id2).classList.add('green');
          $(id2).classList.remove('red');
        }
      }

      if (this.hasAttribute('data-pq6')) {
        let id1 = 'pqsop_details_pq6_target_set_install_prepaid_meters_1';
        let id2 = 'pqsop_details_pq6_target_achieved_install_prepaid_meters_1';
        let id3 = 'pqsop_details_pq6_target_achieved_install_prepaid_meters_staus_1';

        if ($(id2).value && $(id1).value) {
          if (strToNumber($(id2).value) * 1 < strToNumber($(id1).value) * 1) {
            $(id3).value = 'Non-compliant';
            $(id3).classList.add('red');
            $(id3).classList.remove('green');
          }
          else {
            $(id3).value = 'Compliant';
            $(id3).classList.add('green');
            $(id3).classList.remove('red');
          }
        }

        let id21 = 'pqsop_details_pq6_target_set_install_prepaid_meters_2';
        let id22 = 'pqsop_details_pq6_target_achieved_install_prepaid_meters_2';
        let id23 = 'pqsop_details_pq6_target_achieved_install_prepaid_meters_staus_2';

        if ($(id22).value && $(id21).value) {
          if (strToNumber($(id22).value) * 1 < strToNumber($(id21).value) * 1) {
            $(id23).value = 'Non-compliant';
            $(id23).classList.add('red');
            $(id23).classList.remove('green');
          }
          else {
            $(id23).value = 'Compliant';
            $(id23).classList.add('green');
            $(id23).classList.remove('red');
          }
        }
      }

      if (this.hasAttribute('data-onkeyup')) {
        let funcName = this.getAttribute('data-onkeyup');
        let prefix = this.getAttribute('data-prefix');
        let pat = /\((.*?)\)/g;
        let args = pat.exec(funcName);
        funcName = funcName.replace(args[0], '');
        prefix = prefix + ', ' + args[1] + ', ' + objid;
        args = prefix.split(',');
        callFunctions[funcName].apply(callFunctions, args);
      }
      updatefield(this);
    });

    inputElement.addEventListener('blur', function (evt) {
      if (this.hasAttribute('data-number') || this.hasAttribute('data-amount')) {
        let decimal = this.hasAttribute('data-amount') ? 2 : 0;
        if (this.hasAttribute('data-decimal')) decimal = this.getAttribute('data-decimal');
        if (blankAllowed == 'N' || this.value) {
          this.value = toFixed(strToNumber(this.value), decimal);
          if (this.hasAttribute('data-no-comma')) {
            this.value = strToNumber(this.value);
          }
        }
      }
    });

    inputElement.addEventListener('focus', function (evt) {
      if (this.classList.contains('is-invalid')) {
        this.classList.remove('is-invalid');
        this.closest('a.error-tag').removeAttribute('title');
      }
      if (this.hasAttribute('data-number') || this.hasAttribute('data-amount')) {
        if (blankAllowed == 'N' || this.value) {
          this.value = strToNumber(this.value);
        }
      }
    });

    inputElement.addEventListener('change', function (evt) {
      CheckConditionals(this);
      if (['date'].includes(nodeType)) {
        RunFormulas(selector);
      }
      if (this.hasAttribute('data-onchange')) {
        let funcName = this.getAttribute('data-onchange');
        let prefix = this.getAttribute('data-prefix');
        let pat = /\((.*?)\)/g;
        let args = pat.exec(funcName);
        funcName = funcName.replace(args[0], '');
        prefix = prefix + ', ' + args[1] + ', ' + objid;
        args = prefix.split(',');
        callFunctions[funcName].apply(callFunctions, args);
      }

      if (this.hasAttribute('data-climInvoice')) {
        let prefix = this.getAttribute('data-prefix');
        prefix = prefix + ', ' + objid;
        climInvoiceDateinit(prefix);
      }

      updatefield(this);
    });
  }
  if (
    (nodeName === 'SELECT' /* || ['radio', 'checkbox'].includes(nodeType)*/) &&
    !inputEventChecker.hasEvent(objid, selector)
  ) {
    inputEventChecker.addEvent(objid, selector);
    inputElement.addEventListener('change', function (evt) {
      CheckConditionals(this);
      RunFormulas(selector);
      if (this.hasAttribute('data-onchange')) {
        let funcName = this.getAttribute('data-onchange');
        let prefix = this.getAttribute('data-prefix');
        let pat = /\((.*?)\)/g;
        let args = pat.exec(funcName);
        funcName = funcName.replace(args[0], '');
        prefix = prefix + ', ' + args[1] + ',' + objid;
        args = prefix.split(',');
        callFunctions[funcName].apply(callFunctions, args);
      }
      updatefield(this);
    });
  }
  //}
  CheckConditionals(inputElement);
  // Fire Event
  if (callEvent) {
    inputElement.dispatchEvent(new Event(callEvent, { cancelable: true, bubbles: true }))
  }
}

export const pq4allstatus = () => {
  let id1 = 'pqsop_details_pq4_sub_previous_status';
  let id2 = 'pqsop_details_pq4_sub_current_status';
  let id3 = 'pqsop_details_subsidy_status';
  let id4 = 'pqsop_details_pq4_status';

  if ($(id1).value && $(id2).value && $(id3).value) {
    if ($(id1).value === 'Compliant' && $(id2).value === 'Compliant' && $(id3).value === 'Compliant') {
      $(id4).value = 'Compliant';
      $(id4).classList.add('green');
      $(id4).classList.remove('red');
    }
    else {
      $(id4).value = 'Non-compliant';
      $(id4).classList.add('red');
      $(id4).classList.remove('green');
    }
  }
  else {
    $(id4).value = '';
  }
}

export const init = (selector = '') => {
  selector = (selector) ? selector : ['.form-control'];
  let obj = document.querySelectorAll(selector);
  inputEventChecker.removeGroupEvent(selector);
  for (let i = 0; i < obj.length; i++) {
    let objid = obj[i].getAttribute('id');
    if (inputEventChecker.hasEvent(objid, selector)) { continue; }
    attributesMonitor(obj[i]);
    let elementtype = objid ? $(objid).type : '';
    if (obj[i].hasAttribute('data-click')) {
      document.querySelector('#' + objid).addEventListener('click', function (evt) {
        $('pwd').type = $('pwd').type === 'text' ? 'password' : 'text';
        if ($('pwd').type === 'text')
          $(objid).innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M942.2 486.2C847.4 286.5 704.1 186 512 186c-192.2 0-335.4 100.5-430.2 300.3a60.3 60.3 0 0 0 0 51.5C176.6 737.5 319.9 838 512 838c192.2 0 335.4-100.5 430.2-300.3 7.7-16.2 7.7-35 0-51.5zM512 766c-161.3 0-279.4-81.8-362.7-254C232.6 339.8 350.7 258 512 258c161.3 0 279.4 81.8 362.7 254C791.5 684.2 673.4 766 512 766zm-4-430c-97.2 0-176 78.8-176 176s78.8 176 176 176 176-78.8 176-176-78.8-176-176-176zm0 288c-61.9 0-112-50.1-112-112s50.1-112 112-112 112 50.1 112 112-50.1 112-112 112z"></path></svg>';
        else
          $(objid).innerHTML = '<svg stroke="currentColor" fill="currentColor" stroke-width="0" viewBox="0 0 1024 1024" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg"><path d="M942.2 486.2Q889.47 375.11 816.7 305l-50.88 50.88C807.31 395.53 843.45 447.4 874.7 512 791.5 684.2 673.4 766 512 766q-72.67 0-133.87-22.38L323 798.75Q408 838 512 838q288.3 0 430.2-300.3a60.29 60.29 0 0 0 0-51.5zm-63.57-320.64L836 122.88a8 8 0 0 0-11.32 0L715.31 232.2Q624.86 186 512 186q-288.3 0-430.2 300.3a60.3 60.3 0 0 0 0 51.5q56.69 119.4 136.5 191.41L112.48 835a8 8 0 0 0 0 11.31L155.17 889a8 8 0 0 0 11.31 0l712.15-712.12a8 8 0 0 0 0-11.32zM149.3 512C232.6 339.8 350.7 258 512 258c54.54 0 104.13 9.36 149.12 28.39l-70.3 70.3a176 176 0 0 0-238.13 238.13l-83.42 83.42C223.1 637.49 183.3 582.28 149.3 512zm246.7 0a112.11 112.11 0 0 1 146.2-106.69L401.31 546.2A112 112 0 0 1 396 512z"></path><path d="M508 624c-3.46 0-6.87-.16-10.25-.47l-52.82 52.82a176.09 176.09 0 0 0 227.42-227.42l-52.82 52.82c.31 3.38.47 6.79.47 10.25a111.94 111.94 0 0 1-112 112z"></path></svg>';
      });
    }
    if (obj[i].hasAttribute('data-required') || obj[i].hasAttribute('data-number') || obj[i].hasAttribute('data-amount') || elementtype === 'file' || elementtype === 'email' || elementtype === 'radio' || elementtype === 'select-one') {
      let msg = 'Required';
      if (obj[i].hasAttribute('silver-notempty-message'))
        msg = obj[i].getAttribute('silver-notempty-message');
      let frmObj = obj[i].closest('form');
      let errid = objid + '_err';
      const erElm = document.createElement('small');
      erElm.id = errid;
      erElm.style.display = 'none';
      erElm.style.textAlign = 'center';
      erElm.className = 'silver-block';
      erElm.innerHTML = msg;
      // let html = '<small id="' + errid + '" style="display: none;" class="silver-block">' + msg + '</small>';
      if (frmObj.querySelector('#' + errid) !== null)
        frmObj.querySelector('#' + errid).remove();
      let parentObj = obj[i].closest('.form-group');
      if (parentObj) {
        parentObj.append(erElm);
        // parentObj.innerHTML = parentObj.innerHTML + html;
      }
      let nodetype = $(objid).nodeName;
      if (elementtype === 'file') {
        document.querySelector('#' + objid).addEventListener('change', function (evt) {
          checkfile(this);
        });
      }
      if (elementtype === 'radio') {
        if ($(objid).hasAttribute('data-onchange')) {
          document.querySelector('#' + objid).addEventListener('click', function (evt) {
            let funcName = this.getAttribute('data-onchange');
            let prefix = this.getAttribute('data-prefix');
            let pat = /\((.*?)\)/g;
            let args = pat.exec(funcName);
            funcName = funcName.replace(args[0], '');
            prefix = prefix + ', ' + args[1];
            args = prefix.split(',');
            callFunctions[funcName].apply(callFunctions, args);
          });
        }
      }

      initkeyup(obj, i, selector);

      // if (nodetype === 'SELECT') {
      //     document.querySelector('#' + objid).addEventListener('change', function (evt) {
      //         if (this.hasAttribute('data-onchange')) {
      //             let funcName = this.getAttribute('data-onchange');
      //             let prefix = this.getAttribute('data-prefix');
      //             let pat = /\((.*?)\)/g;
      //             let args = pat.exec(funcName);
      //             funcName = funcName.replace(args[0], '');
      //             prefix = prefix + ', ' + args[1] + ',' + objid;
      //             args = prefix.split(',');
      //             callFunctions[funcName].apply(callFunctions, args);
      //         }
      //         updatefield(this);
      //     });
      // }
    }

    /* ========= RDSS > Award Module > LR&SM On change events ======== */
    if (objid) {
      // Award Module > LR
      if (elementtype === 'select-one' && objid.startsWith("lr_award_module_lr_present_status")) {
        let frmEle = document.getElementById('lrPreFrm');
        let ele = document.getElementById(objid);
        if (ele && frmEle) {
          let optionIdSplit = objid.split('_');
          let lrPrefix = 'lr_award_module';
          if (ele.value === '1') {
            // RFP
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'N');
            // Technical
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'N');
            // Financial
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'N');
            // Participants
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).value = '';
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');
            // Successful bidder
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
            // LOA
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
          }
          else if (ele.value === '2') {
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');
            let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
            if (rfpDateEle) {
              if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
              }
            }

            // ========== Remove Validation
            // Technical
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'N');
            // Financial
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'N');
            // Participants
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).value = '';
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');
            // Successful bidder
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
            // LOA
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
          }
          else if (ele.value === '3') {
            // RFP
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');
            let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
            if (rfpDateEle) {
              if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
              }
            }

            // Technical
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'Y');
            let technicalDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`);
            if (technicalDateEle) {
              if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
              }
            }

            // ========== Remove Validation
            // Financial
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'N');
            // Participants
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).value = '';
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');
            // Successful bidder
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
            // LOA
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
          }
          else if (ele.value === '4') {
            // RFP
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');
            let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
            if (rfpDateEle) {
              if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
              }
            }
            // Technical
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'Y');
            let technicalDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`);
            if (technicalDateEle) {
              if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
              }
            }
            // Financial
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'Y');
            let financialDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`);
            if (financialDateEle) {
              if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
              }
            }
            // ========== Remove Validation
            // Participants
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).value = '';
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');
            // Successful bidder
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
            // LOA
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
          }
          else if (ele.value === '5') {
            // RFP
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');
            let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
            if (rfpDateEle) {
              if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
              }
            }
            // Technical
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'Y');
            let technicalDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`);
            if (technicalDateEle) {
              if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
              }
            }
            // Financial
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'Y');
            let financialDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`);
            if (financialDateEle) {
              if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
              }
            }
            // Participants
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'Y');

            // ========== Remove Validation
            // Successful bidder
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).value = '';
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');
            // LOA
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute("readonly", "readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).value = '';
            document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
          }
          else if (ele.value === '6') {
            // RFP
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'Y');
            let rfpDateEle = document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`);
            if (rfpDateEle) {
              if (rfpDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
              }
            }
            // Technical
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'Y');
            let technicalDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`);
            if (technicalDateEle) {
              if (technicalDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
              }
            }
            // Financial
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'Y');
            let financialDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`);
            if (financialDateEle) {
              if (financialDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'Y');
              } else {
                document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
              }
            }
            // Participants
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'Y');
            // Successful bidder
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'Y');

            // LOA
            let checkDiv = document.getElementById("lrPreFrm");
            if (checkDiv) {
              document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).removeAttribute("readonly");
              document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'Y');
              let loaDateEle = document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`);
              if (loaDateEle) {
                if (loaDateEle.value !== '' && ele.value !== '7' && ele.value !== '8') {
                  document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'N');
                }
              }
            }
          }
          else if (ele.value === '7' || ele.value === '8') {
            // RFP
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_date_${optionIdSplit[6]}_7`).setAttribute('data-required', 'N');

            // Technical
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_11`).setAttribute('data-required', 'N');

            // Financial
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_13`).setAttribute('data-required', 'N');

            // Participants
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_participants_${optionIdSplit[6]}_15`).setAttribute('data-required', 'N');

            // Successful bidder
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).removeAttribute("readonly");
            document.getElementById(`${lrPrefix}_lr_name_of_successful_bidder_${optionIdSplit[6]}_16`).setAttribute('data-required', 'N');

            let checkDiv = document.getElementById("lrPreFrm");
            if (checkDiv) {
              // LOA
              document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).removeAttribute("readonly");
              document.getElementById(`${lrPrefix}_lr_actual_date_of_loa_${optionIdSplit[6]}_18`).setAttribute('data-required', 'N');
            }

            // File Upload
            document.getElementById(`${lrPrefix}_lr_actual_rfp_issue_upload_${optionIdSplit[6]}_8`).setAttribute('data-required', 'N');
            document.getElementById(`${lrPrefix}_lr_actual_technical_bid_opening_upload_${optionIdSplit[6]}_12`).setAttribute('data-required', 'N');
            document.getElementById(`${lrPrefix}_lr_actual_financial_bid_opening_upload_${optionIdSplit[6]}_14`).setAttribute('data-required', 'N');
            document.getElementById(`${lrPrefix}_lr_actual_loa_upload_${optionIdSplit[6]}_19`).setAttribute('data-required', 'N');
          }
        }
      }
      if (elementtype === 'date' && objid.startsWith("lr_award_module_lr_contract_agreement_date")) {
        let ele = document.getElementById(objid);
        const rect = ele.getBoundingClientRect();
        const isVisible = rect.bottom > 0 && rect.top < window.innerHeight;
        if (ele && isVisible) {
          let optionIdSplit = objid.split('_');
          let lrPrefix = 'lr_award_module';
          if (ele.value !== '') {
            document.getElementById(`${lrPrefix}_lr_contract_agreement_upload_${optionIdSplit[7]}_21`).setAttribute('data-required', 'Y');
          } else {
            document.getElementById(`${lrPrefix}_lr_contract_agreement_upload_${optionIdSplit[7]}_21`).setAttribute('data-required', 'N');
          }
        }
      }

      // Award Module > SM
      let smPreFrmEle = document.getElementById('smPreFrm');
      if (smPreFrmEle) {
        if (elementtype === 'select-one' && objid.startsWith("sm_award_module_sm_present_status")) {
          let elesm = document.getElementById(objid);
          if (elesm) {
            let optionIdSplit = objid.split('_');
            let smPrefix = 'sm_award_module';
            if (elesm.value === '1') {
              // RFP
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'N');
              // Technical
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'N');
              // Financial
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'N');
              // Participants
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).value = '';
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');
              // Successful bidder
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
              // LOA
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
            }
            else if (elesm.value === '2') {
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
              let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
              if (rfpDateEle) {
                if (rfpDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
                }
              }

              // ========== Remove Validation
              // Technical
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'N');
              // Financial
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'N');
              // Participants
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).value = '';
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');
              // Successful bidder
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
              // LOA
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
            }
            else if (elesm.value === '3') {
              // RFP
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
              let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
              if (rfpDateEle) {
                if (rfpDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
                }
              }
              // Technical
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'Y');
              let technicalDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`);
              if (technicalDateEle) {
                if (technicalDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
                }
              }
              // ========== Remove Validation
              // Financial
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'N');
              // Participants
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).value = '';
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');
              // Successful bidder
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
              // LOA
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
            }
            else if (elesm.value === '4') {
              // RFP
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
              let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
              if (rfpDateEle) {
                if (rfpDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
                }
              }
              // Technical
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'Y');
              let technicalDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`);
              if (technicalDateEle) {
                if (technicalDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
                }
              }
              // Financial
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'Y');
              let financialDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`);
              if (financialDateEle) {
                if (financialDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
                }
              }
              // ========== Remove Validation
              // Participants
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).value = '';
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');
              // Successful bidder
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
              // LOA
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
            }
            else if (elesm.value === '5') {
              // RFP
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
              let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
              if (rfpDateEle) {
                if (rfpDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
                }
              }
              // Technical
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'Y');
              let technicalDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`);
              if (technicalDateEle) {
                if (technicalDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
                }
              }
              // Financial
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'Y');
              let financialDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`);
              if (financialDateEle) {
                if (financialDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
                }
              }
              // Participants
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'Y');

              // ========== Remove Validation
              // Successful bidder
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).value = '';
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');
              // LOA
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute("readonly", "readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).value = '';
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');
            }
            else if (elesm.value === '6') {
              // RFP

              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'Y');
              let rfpDateEle = document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`);
              if (rfpDateEle) {
                if (rfpDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
                }
              }
              // Technical
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'Y');
              let technicalDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`);
              if (technicalDateEle) {
                if (technicalDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
                }
              }
              // Financial
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'Y');
              let financialDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`);
              if (financialDateEle) {
                if (financialDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
                }
              }
              // Participants
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'Y');

              // Successful bidder
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'Y');

              // LOA
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'Y');
              let loaDateEle = document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`);
              if (loaDateEle) {
                if (loaDateEle.value !== '' && elesm.value !== '7' && elesm.value !== '8') {
                  document.getElementById(`${smPrefix}_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'Y');
                } else {
                  document.getElementById(`${smPrefix}_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'N');
                }
              }
            }
            else if (elesm.value === '7' || elesm.value === '8') {
              // RFP
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_date_${optionIdSplit[6]}_4_2`).setAttribute('data-required', 'N');

              // Technical
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_technical_bid_opening_${optionIdSplit[6]}_4_6`).setAttribute('data-required', 'N');

              // Financial
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_financial_bid_opening_${optionIdSplit[6]}_4_8`).setAttribute('data-required', 'N');

              // Participants
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_name_of_participants_${optionIdSplit[6]}_4_10`).setAttribute('data-required', 'N');

              // Successful bidder
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_name_of_successful_bidder_${optionIdSplit[6]}_4_11`).setAttribute('data-required', 'N');

              // LOA
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).removeAttribute("readonly");
              document.getElementById(`${smPrefix}_sm_actual_date_of_loa_${optionIdSplit[6]}_5_1`).setAttribute('data-required', 'N');

              // File Upload
              document.getElementById(`${smPrefix}_sm_actual_rfp_issue_upload_${optionIdSplit[6]}_4_3`).setAttribute('data-required', 'N');
              document.getElementById(`${smPrefix}_sm_actual_technical_bid_opening_upload_${optionIdSplit[6]}_4_7`).setAttribute('data-required', 'N');
              document.getElementById(`${smPrefix}_sm_actual_financial_bid_opening_upload_${optionIdSplit[6]}_4_9`).setAttribute('data-required', 'N');
              document.getElementById(`${smPrefix}_sm_actual_loa_upload_${optionIdSplit[6]}_5_2`).setAttribute('data-required', 'N');
            }
          }
        }
      }
      if (elementtype === 'date' && objid.startsWith("sm_award_module_sm_contract_agreement_date")) {
        let ele = document.getElementById(objid);
        const rect = ele.getBoundingClientRect();
        const isVisible = rect.bottom > 0 && rect.top < window.innerHeight;
        if (ele && isVisible) {
          let optionIdSplit = objid.split('_');
          let lrPrefix = 'sm_award_module';
          if (ele.value !== '') {
            document.getElementById(`${lrPrefix}_sm_contract_agreement_upload_${optionIdSplit[7]}_6`).setAttribute('data-required', 'Y');
          } else {
            document.getElementById(`${lrPrefix}_sm_contract_agreement_upload_${optionIdSplit[7]}_6`).setAttribute('data-required', 'N');
          }
        }
      }
      if (elementtype === 'date' && objid.startsWith("sm_award_module_sm_date_of_execution")) {
        let ele = document.getElementById(objid);
        const rect = ele.getBoundingClientRect();
        const isVisible = rect.bottom > 0 && rect.top < window.innerHeight;
        if (ele && isVisible) {
          let optionIdSplit = objid.split('_');
          let lrPrefix = 'sm_award_module';
          if (ele.value !== '') {
            document.getElementById(`${lrPrefix}_sm_date_of_execution_upload_${optionIdSplit[7]}_10`).setAttribute('data-required', 'Y');
          } else {
            document.getElementById(`${lrPrefix}_sm_date_of_execution_upload_${optionIdSplit[7]}_10`).setAttribute('data-required', 'N');
          }
        }
      }
    }
  }
}

export const initselect = (selector = '') => {
  selector = (selector) ? selector : ['.form-control'];
  let obj = document.querySelectorAll(selector);
  for (let i = 0; i < obj.length; i++) {
    let objid = obj[i].getAttribute('id');
    if (inputEventChecker.hasEvent(objid, selector)) { continue; }
    attributesMonitor(obj[i]);
    let elementtype = objid ? $(objid).type : '';
    if (obj[i].hasAttribute('data-required') || obj[i].hasAttribute('data-number') || obj[i].hasAttribute('data-amount') || elementtype === 'file' || elementtype === 'email') {
      let nodetype = $(objid).nodeName;
      initkeyup(obj, i, selector);
      // if (nodetype === 'SELECT') {
      //     document.querySelector('#' + objid).addEventListener('change', function (evt) {
      //         if (this.hasAttribute('data-onchange')) {
      //             let funcName = this.getAttribute('data-onchange');
      //             let prefix = this.getAttribute('data-prefix');
      //             let pat = /\((.*?)\)/g;
      //             let args = pat.exec(funcName);
      //             funcName = funcName.replace(args[0], '');
      //             prefix = prefix + ', ' + args[1] + ',' + objid;
      //             args = prefix.split(',');
      //             callFunctions[funcName].apply(callFunctions, args);
      //         }
      //         updatefield(this);
      //     });
      // }
    }
  }
}

export const checkfile = (obj) => {
  let objid = obj.getAttribute('id');
  if ($(objid).value === '') return 0;
  let parentObj = obj.closest('.form-group');
  let errid = objid + '_err';
  $(errid).style.display = 'none';
  let mimtype = obj.files[0].type;
  let serr = '0';
  let ferr = '1';
  let data_files_allowed = $(objid).getAttribute('data-files-allowed');
  let data_size = $(objid).getAttribute('data-size');
  let data_size_allowed = $(objid).getAttribute('data-size-allowed');
  let extary = data_files_allowed.split(',');
  $(errid).innerHTML = 'Invalid File, Allowed format are ' + data_files_allowed;
  if (data_size * 1 > 0 && $(objid).files[0].size > data_size * 1) {
    serr = '1';
    $(errid).innerHTML = 'Invalid File, Allowed size is ' + data_size_allowed;
  }
  if (serr === '0') {
    for (let i = 0; i < extary.length; i++) {
      let mtype = 'image/' + extary[i];
      if (extary[i] === 'jpg') mtype = 'image/jpeg';
      if (mtype === mimtype) { let imgsrc = URL.createObjectURL($(objid).files[0]); ferr = '0'; }
      if (extary[i] === 'pdf' && mimtype === 'application/pdf') { ferr = '0'; }
      if (extary[i] === 'doc' && mimtype === 'application/msword') { ferr = '0'; }
      if (extary[i] === 'docx' && mimtype === 'application/vnd.openxmlformats-officedocument.wordprocessingml.document') { ferr = '0'; }
      if (extary[i] === 'xls' && mimtype === 'application/vnd.ms-excel') { ferr = '0'; }
      if (extary[i] === 'xlsx' && mimtype === 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet') { ferr = '0'; }
      if (extary[i] === 'ppt' && mimtype === 'application/vnd.ms-powerpoint') { ferr = '0'; }
      if (extary[i] === 'pptx' && mimtype === 'application/vnd.openxmlformats-officedocument.presentationml.presentation') { ferr = '0'; }
      if (extary[i] === 'mp3' && mimtype === 'audio/mpeg') { ferr = '0'; }
      if (extary[i] === 'mpeg' && mimtype === 'video/mpeg') { ferr = '0'; }
      if (extary[i] === 'wmv' && mimtype === 'video/x-ms-wmv') { ferr = '0'; }
      if (extary[i] === 'mp4' && mimtype === 'video/mp4') { ferr = '0'; }
      if (extary[i] === 'csv' && mimtype === 'text/csv') { ferr = '0'; }
      if (extary[i] === 'zip' && mimtype === 'application/zip') { ferr = '0'; }
      if (extary[i] === 'srt' && mimtype === '') { ferr = '0'; }
    }
  }
  if (ferr === '1' || serr === '1') {
    parentObj.classList.remove('has-success');
    parentObj.classList.add('has-error');
    $(errid).style.display = 'block';
    $(objid).value = '';
    return 1;
  }
  parentObj.classList.remove('has-error');
  parentObj.classList.add('has-success');
  $(errid).innerHTML = $(objid).files[0].name;
  $(errid).style.display = 'block';
  if ($(objid + 'act'))
    $(objid + 'act').style.display = 'block';
  return 0;
}

export const updatefield = (obj) => {
  let parentObj = obj.closest('.form-group');
  let objid = obj.getAttribute('id');
  let frmObj = obj.closest('form');
  let errid = objid + '_err';
  let err = 0;
  let msg = 'Required';

  if (obj.classList.contains('conditional-hide')) return err;

  const isRequired = (obj.hasAttribute('data-required') && obj.getAttribute('data-required') == 'Y');
  if (isRequired && obj.value.split(' ').join('') === '') {
    err = 1;
  }
  if (obj.hasAttribute('silver-notempty-message')) {
    msg = obj.getAttribute('silver-notempty-message');
  }
  if (err === 0 && obj.hasAttribute('data-pattern')) {
    msg = obj.getAttribute('data-pattern-msg');
  }
  if (err === 0 && obj.hasAttribute('data-min-number')) {
    msg = obj.getAttribute('data-min-number-msg');
  }

  if (frmObj.querySelector('#' + errid) == null) {
    const erElm = document.createElement('small');
    erElm.id = errid;
    erElm.style.display = 'none';
    erElm.style.textAlign = 'center';
    erElm.className = 'silver-block';
    erElm.innerHTML = msg;
    if (parentObj) {
      parentObj.append(erElm);
    }
  }

  if (frmObj.querySelector('#' + errid)) {
    let erElm = frmObj.querySelector('#' + errid);
    erElm.innerHTML = msg;
    if (err === 0 && obj.type === 'email' && obj.value !== '') {
      let patmatch = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      if (!obj.value.match(patmatch)) {
        erElm.innerHTML = 'Invalid Email';
        err = 1;
      }
    }
    if (err === 0 && obj.hasAttribute('data-pattern')) {
      let patmatch = obj.getAttribute('data-pattern');
      if (!obj.value.match(new RegExp(patmatch)))
        err = 1;
    }
    if (err === 0 && obj.hasAttribute('data-min-number') && obj.value !== '') {
      let minnumber = obj.getAttribute('data-min-number');
      if (obj.value * 1 <= minnumber * 1) {
        err = 1;
      }
    }
    if (err === 0 && obj.hasAttribute('data-min-words') && obj.value !== '') {
      let minwords = obj.getAttribute('data-min-words');
      let words = obj.value.split(/[\s,\n]+/).length;
      if (words * 1 < minwords * 1) {
        erElm.innerHTML = 'Min' + minwords + ' Words';
        err = 1;
      }
    }

    let pError = obj.closest('.child-has-error');

    if (err === 1) {
      erElm.style.display = 'block';
      parentObj.classList.remove('has-success');
      parentObj.classList.add('has-error');

      if (pError && pError != null) {
        const pErCount = pError.getAttribute('data-error-num');
        pError.setAttribute('data-error-num', ((pErCount != null) ? parseInt(pErCount) + 1 : 1));
        const pErElm = document.createElement('small');
        pErElm.className = 'silver-block';
        pError.append(pErElm);
        pError.classList.add('has-error');
      }
    } else {
      erElm.style.display = 'none';
      parentObj.classList.remove('has-error');
      parentObj.classList.add('has-success');

      if (pError && pError != null) {
        const pErCount = pError.getAttribute('data-error-num');
        if (pErCount != null && parseInt(pErCount) === 0) {
          // remove small
          let small = pError.querySelectorAll(['small']);
          for (let j = 0; j < small.length; j++) {
            small[j].remove();
          }
          pError.classList.remove('has-error');
        }
      }
    }
  }
  return err;
}

export const filevalidate = (obj) => {
  let parentObj = obj.closest('.form-group');
  let objid = obj.getAttribute('id');
  let frmObj = obj.closest('form');
  let errid = objid + '_err';
  let err = 0;
  let msg = '';

  if (obj.classList.contains('conditional-hide')) return err;

  if (obj.hasAttribute('data-min-file')) {
    let minfile = Number(obj.getAttribute('data-min-file'));
    if (obj.files.length < minfile) {
      err = 1;
    }
    if (obj.hasAttribute('data-min-file-msg')) {
      msg = obj.getAttribute('data-min-file-msg');
    } else {
      msg = 'Minimum ' + minfile + ' files required';
    }
  }

  if (obj.hasAttribute('data-max-file')) {
    let maxfile = Number(obj.getAttribute('data-max-file'));
    if (obj.files.length > maxfile) {
      err = 1;
    }
    if (obj.hasAttribute('data-max-file-msg')) {
      msg = obj.getAttribute('data-max-file-msg');
    } else {
      msg = 'Maximum ' + maxfile + ' files allowed';
    }
  }

  if (frmObj.querySelector('#' + errid) == null) {
    const erElm = document.createElement('small');
    erElm.id = errid;
    erElm.style.display = 'none';
    erElm.style.textAlign = 'center';
    erElm.className = 'silver-block';
    erElm.innerHTML = msg;
    if (parentObj) {
      parentObj.append(erElm);
    }
  }

  if (frmObj.querySelector('#' + errid)) {
    let erElm = frmObj.querySelector('#' + errid);
    erElm.innerHTML = msg;
    if (err === 1) {
      erElm.style.display = 'block';
      parentObj.classList.remove('has-success');
      parentObj.classList.add('has-error');
    } else {
      erElm.style.display = 'none';
      parentObj.classList.remove('has-error');
      parentObj.classList.add('has-success');
    }
  }
  return err;
}

export const validatefrm = (selector = '') => {
  let focusobj = '';
  let success = true;
  let fld = null;
  let pError = document.querySelectorAll(['.child-has-error']);
  for (let i = 0; i < pError.length; i++) {
    // remove small
    let small = pError[i].querySelectorAll(['small']);
    for (let j = 0; j < small.length; j++) {
      small[j].remove();
    }
    pError[i].setAttribute('data-error-num', 0);
    pError[i].classList.remove('has-error');
  }
  selector = (selector) ? selector : ['.form-control'];
  let obj = document.querySelectorAll(selector);
  for (let i = 0; i < obj.length; i++) {
    // Not disabled
    if (obj[i].disabled) continue;
    let err = 0;
    if (
      (obj[i].hasAttribute('data-required') && obj[i].getAttribute('data-required') == 'Y') &&
      obj[i].value.split(' ').join('') === ''
    ) {
      if (fld === '') {
        fld = obj[i];
      }
      err = updatefield(obj[i]);
    }

    if (obj[i].type === 'email' && obj[i].value !== '') {
      err = updatefield(obj[i]);
    }

    if (obj[i].hasAttribute('data-min-words') && obj[i].value !== '') {
      err = updatefield(obj[i]);
    }

    if (obj[i].hasAttribute('data-pattern') && obj[i].value !== '') {
      err = updatefield(obj[i]);
    }

    if (obj[i].hasAttribute('data-min-number') && obj[i].value !== '') {
      err = updatefield(obj[i]);
    }

    if (obj[i].type === 'file' && obj[i].value !== '' && err !== 1) {
    //if (err !== 1 && obj[i].type === 'file') {
      err = filevalidate(obj[i]);
    }

    if (err === 1 && focusobj === '') {
      focusobj = obj[i];
    }

    if (err === 1) {
      //console.log(obj[i]);
      success = false;
    }

    if (err === 0) {
      let parentObj = obj[i].closest('.form-group');
      let objid = obj[i].getAttribute('id');
      let erElm = obj[i].closest('form').querySelector('#' + objid + '_err');
      if (erElm) {
        erElm.style.display = 'none';
        parentObj.classList.remove('has-error');
        parentObj.classList.add('has-success');
      }
    }
  }
  if (focusobj !== '') {
    focusobj.focus();
  }
  return success;
}

export const maxchar = (obj, maxwords) => {
  let words = obj.value.split(/[\s,\n]+/).length;
  if (maxwords * 1 - words * 1 < 0) {
    let trimmed = '';
    trimmed = obj.value.split(/\s+/, maxwords).join(" ");
    trimmed = trimmed.split(/\n+/, maxwords).join("\n");
    trimmed = trimmed.split(/\,+/, maxwords).join(",");
    obj.value = trimmed;
    $(obj.id + '_sdm').innerHTML = '0';
    return false;
  }
  $(obj.id + '_sdm').innerHTML = maxwords * 1 - words * 1;
}

export const removeobj = (obj) => {
  $(obj).value = '';
  $(obj + 'act').style.display = 'none';
}
